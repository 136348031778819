import { FaPaw } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { useAction, useAppState } from "../../../hooks"
import { EAppState } from "../../../types"
import { COOKIE_TOKEN, EPage } from "../../../utils"
import Cookies from 'js-cookie'

type LoginMenuItemProps = {
    icon?: JSX.Element
}

export const LoginMenuItem = ({icon } : LoginMenuItemProps) => {
    const { state } = useAppState()
    const { setAppLoginModalAction, setAppSmallMenuAction, setAppTokenAction } = useAction()
    const navigate = useNavigate()

    const onMenuClick = () => {
        if (state?.token) {
            setAppTokenAction(undefined)
            Cookies.remove(COOKIE_TOKEN)
            navigate(EPage.HOME)
        } else {
            setAppLoginModalAction(true)
        }
        setAppSmallMenuAction(false)
    }

    return <div className='menu_item' onClick={onMenuClick}>
        <div className='menu_item__icon'>
            {icon ? icon : <FaPaw />}
        </div>
        <div className='menu_item__text'>
            {state?.token ? 'Odhlásit se' : 'Přihlásit se'}
        </div>
    </div>
}