import { useEffect, useState } from "react";
import { Cyphers, Headline, HistoryMenu } from "../../components";
import { cyphers2024 } from "../../resources";
import { HistoryPageProps, TCypher } from "../../types";
import { EGame, EHistoryPage } from "../../utils";

export const CyphersPage = ({ setPage, year }: HistoryPageProps) => {
    const [cyphers, setCyphers] = useState<TCypher[]>([])

    useEffect(() => {
        switch (year) {
            case EGame.ZKR2024:
                setCyphers(cyphers2024)
                break;
        }
    }, [year])


    return (
        <div className='page'>
            <HistoryMenu selectedPage={EHistoryPage.CYPHERS} setPage={setPage} />
            <Headline text='Šifry' size={1} />
            <Cyphers cyphers={ cyphers} />
        </div>
    )
}