import { ReactChild } from "react"
import { CgClose } from "react-icons/cg"
import { ErrorMessage } from "../message"

type ModalProps = {
    visible?: boolean,
    headline?: string,
    children: ReactChild,
    closeClick: any,
    error?: string,
}

export const Modal = ({ visible, headline, children, closeClick, error }: ModalProps) => {

    if (!visible) {
        return <></>
    }

    return (
    <div className='modal'>
            <div className='modal__background' onClick={closeClick} />
            <div className='modal__box'>
                <div className='modal__box__headline'>
                    <div className='modal__box__headline__text'>
                        {headline}
                    </div>
                    <div className='modal__box__headline__close'>
                        <CgClose size={24} onClick={closeClick }/>
                    </div>
                </div>
                <div className='modal__error'>
                    {error ? <ErrorMessage text={error} /> : <></>}
                </div>
                <div className='modal__box__body'>
                    {children}
                </div>
        </div>
        </div >
    )
}