import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import './App.scss';
import { Footer, Header, LoginModal, ScrollToTop } from './components';
import { useAction, useAppState } from './hooks';
import { Admin, Forum, History, Home, Info, Registration, Teams } from './pages';
import { COOKIE_TOKEN, EPage } from './utils';


function App() {
    const { state } = useAppState()
    const { setAppTokenAction } = useAction()

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        console.log(queryParameters)
        if (queryParameters) {
            let loginToken = queryParameters.get("loginToken")
            if (loginToken && !state?.token) {
                setAppTokenAction(loginToken)
                Cookies.set(COOKIE_TOKEN, loginToken)
                return
            }            
        }
        if (!state?.token) {
            const cookie = Cookies.get(COOKIE_TOKEN)
            if (cookie) {
                setAppTokenAction(cookie)
            }
        }
    }, [])

    const Layout = useCallback(() => {
        return (
            <>
                <Header />
                <LoginModal />
                <Outlet />
                <Footer/>
            </>
        )
    }, [])

  return (
    <div className="App">
          <BrowserRouter>
              <ScrollToTop />
              <Routes>
                  <Route path="/" element={<Layout />}>
                      <Route index element={<Home />} />
                      <Route path="*" element={<Home />} />
                      <Route path={EPage.INFO} element={<Info />} />
                      <Route path={EPage.REGISTRATION} element={<Registration />} />
                      <Route path={EPage.TEAMS} element={<Teams />} />
                      <Route path={EPage.HISTORY} element={<History />} />
                      <Route path={EPage.FORUM} element={<Forum />} />
                      <Route path={EPage.ADMIN} element={<Admin />} />
                  </Route>
              </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;
