import { SHA256, enc } from 'crypto-js';
import { TCookie, TPlayer } from '../types';

export function hash(message: string) {
    return SHA256(message).toString(enc.Hex)
}

export const notEmptyString = (s: any) => {
    if (!s) {
        return false
    }
    if (typeof (s) !== 'string') {
        return false
    }
    return s.length > 0
} 

export const isValidPhone = (p: string): boolean => {
    return (/^[+]?[()/0-9. -]{9,}$/.test(p))
}

export const isValidEmail = (e: string): boolean => {
    return (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e))
}

export function isValidPlayer(p: TPlayer, i: number): boolean {
    console.log('validate player' + i)
    let valid = true
    if (p.Name.length < 1) {
        return false
    }
    if (i === 0) {
        if (!notEmptyString(p.Email)) {
            return false
        }
    }
    if (p.Email && (p.Email.length > 0)) {
        if (!isValidEmail(p.Email)) {
            return false
        }
    }
    return valid
}