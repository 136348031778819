import Rating from '@mui/material/Rating';
import './page.scss'
import { useAppState } from '../../hooks';
import { getStars, setStars } from '../../api';
import { useEffect, useState } from 'react';

type CypherRatingProps = {
    cypherId: number
}

export const CypherRating = ({ cypherId }: CypherRatingProps) => {
    const [ratingStars, setRatingStars] = useState(0)
    const [ratingTeamStars, setRatingTeamStars] = useState(0)
    const [ratingVotes, setRatingVotes] = useState(0)
    const [ratingStarsLabel, setRatingStarsLabel] = useState(0)
    const { state } = useAppState()

    useEffect(() => {
        refreshRating()
    }, [cypherId])

    useEffect(() => {
        setRatingStarsLabel(ratingStars)
    }, [ratingStars])

    const onRatingChange = (newValue: number | null) => {
        if (newValue && state?.token && newValue != ratingTeamStars) {
            setStars({
                cypherId: cypherId,
                token: state?.token,
                stars: newValue
            }).then(res => {
                if (!res.errorMessage) {
                    setRatingStars(newValue)
                    refreshRating()
                } 
            })
        }
    }

    const refreshRating = () => {
        setRatingStars(0)
        setRatingVotes(0)
        setRatingTeamStars(0)
        getStars({ cypherId: cypherId, token: state?.token })
            .then(res => {
                console.log(res)
                if (res.stars) {
                    setRatingStars(res.stars)
                }
                if (res.teamStars) {
                    setRatingTeamStars(res.teamStars)
                }
                if (res.votes) {
                    setRatingVotes(res.votes)
                }
            })
    }

    const onRatingHover = (newValue: number | null) => {
        if (newValue && newValue > 0) {
            setRatingStarsLabel(newValue)
        } else {
            setRatingStarsLabel(ratingStars)
        }
    }

    function getRatingLabel(): string {
        if (!state?.token) {
            return 'Pro hodnocení šifry se prosím přihlašte'
        }
        switch (ratingStarsLabel) {
            case 1: return 'Tohle už prosím vícekrát ne!'
            case 2: return 'Nic moc.'
            case 3: return 'Taková obyčejná šifa, nezaujme, neurazí.'
            case 4: return 'Takhle byla fajn ;)'
            case 5: return 'Lahůdka!'
        }
        
        if (ratingTeamStars > 0) {
            return `Vaše hodnocení: ${ratingTeamStars}*`
        } else {
            return 'Zatím jste šifru nehodnotili, budeme rádi za každé hodnocení a případné komentáře!'
        }
        
    }

    return (
        <>
            <div className='rating'>
                <Rating
                    key={ratingStars}
                    name="simple-controlled"
                    value={ratingStars}
                    onChange={(event, newValue) => {
                        onRatingChange(newValue);
                    }}
                    className='rating__stars'
                    onChangeActive={(event, newValue) => {
                        onRatingHover(newValue);
                    }}
                    readOnly={!state?.token}
                    onClick={(event) => {
                        const targetValue = (event.target as HTMLElement).getAttribute('value');
                        if (targetValue) {
                            onRatingChange(Number(targetValue));
                        }
                    }}
                />
                <div className='rating__votes'>
                    {`(${ratingVotes} hlasů)`  }
                </div>
            </div>
            <div className='rating__label'>
                {getRatingLabel()}
            </div>
            
        </>
    )
}