import { useAppState } from "../../../hooks"
import { Button } from "../../form"
import { Modal } from "../Modal"

type ConfirmModalProps = {
    visible: boolean,
    onCloseClick: any,
    headline: string,
    text: string,
    onConfirm: any
}

export const ConfirmModal = ({ visible, onCloseClick, headline, text, onConfirm}: ConfirmModalProps) => {
    const { state } = useAppState()
    return (
        <Modal visible={visible} headline={ headline} closeClick={onCloseClick} error=''>
            <>
                {text}
                <Button onClick={onConfirm } text='Potvrdit'/>
            </>
        </Modal>
    )
}
