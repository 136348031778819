export enum EPage {
    HOME = 'home',
    INFO = 'info',
    REGISTRATION = 'registration',
    TEAMS = 'teams',
    FORUM = 'forum',
    ADMIN = 'admin',
    HISTORY = 'history'
}

export enum EAdminPage {
    PAYMENTS = 'payments',
    NEWS = 'news',
    CONTACTS = 'contacts',
    PASSWORD = 'password'
}

export enum EHistoryPage {
    ABOUT = 'about',
    CYPHERS = 'cyphers',
    STATS = 'stats'
}

export enum ETextInputType {
    INPUT = 'input',
    TEXTAREA = 'textarea',
    PASSWORD = 'password',
}

export enum EMessageType {
    ERROR = 'error',
    SUCCESS = 'success'
}

export enum EUserType {
    ADMIN = 1,
    TEAM = 0,
    CANCELLED = 2
}

export enum EGame {
    ZKR2024 = 'ZKR2024'
}

export enum EForumEndpoint {
    FORUM = 'forum',
    ADMIN_FORUM = 'admin/forum'
}