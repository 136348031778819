import { useEffect, useState } from "react"
import { getForumMessages } from "../../api"
import { Headline, Loading, Paragraph } from "../../components"
import { TForumMessage } from "../../types"
import { FORUM_ORG_NEWS } from "../../utils"

export const OrgMessages = () => {
    const [messages, setMessages] = useState<TForumMessage[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        reloadMessages()
    }, [])

    const reloadMessages = () => {
        getForumMessages({
            forum: FORUM_ORG_NEWS
        }).then(res => {
            if (res.messages) {
                setMessages(res.messages.slice(0,3))
            }
            setLoading(false)
        })
    }

    return (
        <div className='org_messages'>
            <Headline text='Novinky' size={3} />
            {loading ? <Loading size={40} /> :
                <div className='org_messages__messages'>
                    {
                        messages.map(m =>
                            <Paragraph>
                                <div className='org_messages__messages__message'>
                                    <div className='org_messages__messages__message__headline'>
                                        {m.Date?.substring(0, m.Date?.lastIndexOf(' '))} <strong>{m.Author}</strong>
                                    </div>
                                    <div className='org_messages__messages__message__message' dangerouslySetInnerHTML={{ __html: m.Message }}/>
                                </div>
                            </Paragraph>
                        )
                    }
                </div>
            }
        </div>
    )
}