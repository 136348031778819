import { useEffect, useState } from "react"
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getTeams } from "../../api"
import { TUser } from "../../types"
import { EPage } from "../../utils";
import { Loading } from "../loading";
import { Headline, Paragraph } from "../page"

export const NewTeams = () => {
    const [teams, setTeams] = useState<TUser[]>()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadTeams()
    }, [])

    const loadTeams = () => {
        setLoading(true)
        getTeams().then(res => {
            if (res.teams) {
                setTeams(res.teams.sort((a, b) => b.Id - a.Id).slice(0, 5))
            }
            setLoading(false)
        })
    }

    return (
        <div className='mew_teams' onClick={ () => loadTeams()}>
            <Headline text='Poslední registrované týmy' size={3} />
            {loading ? <Loading size={40} /> :
                <Paragraph>
                    <ul>
                        {
                            teams?.map(t =>
                                <div className='mew_teams__team'>
                                    <li>{t.Name}</li>
                                </div>

                            )
                        }
                    </ul>
                </Paragraph>
            }
            <Paragraph>
                <>
                    <span className='href' onClick={() => navigate(EPage.TEAMS)}> Všechny týmy <MdOutlineKeyboardDoubleArrowRight size={20 } className='icon'/></span>
                </>
            </Paragraph>
        </div>
    )
}