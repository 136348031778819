import { TbUserCircle } from 'react-icons/tb'
import { MdLogout } from "react-icons/md";
import { useAction } from '../../../hooks'
import { EPage } from '../../../utils'
import { LoginMenuItem, MenuItem } from '../../header/menu'
import '../modal.scss'
import './userModal.scss'

type UserModalProps = {
    visible?: boolean,
    closeClick: any
}

export const UserModal = ({ visible, closeClick }: UserModalProps) => {
    const { setAppTokenAction } = useAction()
    if (!visible) {
        return <></>
    }

    return (
        <div className='modal'>
            <div className='user__modal__background' onClick={closeClick} >
            <div className='user__modal__box'>
                <MenuItem page={EPage.REGISTRATION} text='Stránka týmu' icon=<TbUserCircle /> />
                    <LoginMenuItem icon={<MdLogout />} />
                </div>
            </div>
         </div>
        )
}