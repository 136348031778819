import { useEffect, useState } from 'react';
import { TbMoneybag } from "react-icons/tb";
import { getTeams } from '../../api';
import { Headline, Paragraph } from '../../components';
import { TUser } from '../../types';
import { LoadingPage } from '../loading/LoadingPage';
import '../page.scss';
import './teams.scss';

export const Teams = () => {
    const [teams, setTeams] = useState<TUser[]>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getTeams().then(res => {
            if (res.teams) {
                setTeams(res.teams)
            }
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <LoadingPage />
    }

    return (
        <>
            <div className='page'>
                <Headline text='Seznam přihlášených týmů' size={1} />

                <Paragraph>
                    <p>Na hru je přihlášeno celkem <strong>{teams?.length || 0} týmů</strong>, z toho {teams?.filter(t => t.Payed).length } má zaplacené startovné.</p>
                </Paragraph>
                {
                    teams ? 
                        <div className='teams_list'>
                            {teams.map(t =>
                                <div className='teams_list__team'>                                     
                                    <div className='teams_list__team__name'> 
                                        {t.Payed ?
                                            <div className='teams_list__team__name__badge'>
                                                <TbMoneybag /> 
                                            </div>
                                            : ''}
                                        {t.Name}
                                    </div>
                                    <div className='teams_list__team__players'>
                                        {t.Players?.map((player, playerIndex) =>
                                            <div className='teams_list__team__players__player'>
                                                {player.Name}
                                                {playerIndex < t.Players!.length - 1 && ','}
                                            </div>
                                        )}
                                    </div>
                                </div> 
                                )}
                        </div>
                        : ''
                }
            </div>
        </>
        )
}