import { Dispatch, useEffect, useState } from "react"
import { CiCircleRemove } from 'react-icons/ci';
import { TPlayer, TUser } from "../../../types"
import { ETextInputType, isValidEmail, notEmptyString } from "../../../utils"
import { TextInput } from "../../form"
import { Headline } from "../../page"
import './player.scss';

type PlayerProps = {
    player: TPlayer,
    ord: number,
    showErrors: boolean,
    user: TUser,
    setUser: Dispatch<React.SetStateAction<TUser>>,
}

export const Player = ({ player, ord, showErrors, user, setUser }: PlayerProps) => {
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')

    useEffect(() => {
        setNameError('')
        setEmailError('')
        if (showErrors) {
            if (ord == 1) {
                if (!notEmptyString(player.Email)) {
                    setEmailError('Je potřeba zadat kontaktní email týmu!')
                }
            }
            if (notEmptyString(player.Email) && !isValidEmail(player.Email || '')) {
                setEmailError('Toto nevypadá jako emailová adresa!')
            }
            if (!notEmptyString(player.Name)) {
                setNameError('Zadejte jméno nebo přezdívku hráče!')
            }
        }
    }, [showErrors])

    const setPlayerName = (newName: string) => {
        let updatedList = user.Players?.map(item => {
            if (item.Id == player.Id) {
                return { ...item, Name: newName };
            }
            return item;
        });

        setUser(prevUser => ({
            ...prevUser,
            Players: updatedList
        }))
    }

    const setPlayerEmail = (newEmail: string) => {
        let updatedList = user.Players?.map(item => {
            if (item.Id == player.Id) {
                return { ...item, Email: newEmail };
            }
            return item;
        });

        setUser(prevUser => ({
            ...prevUser,
            Players: updatedList
        }))
    }

    const canRemovePlayer = () => {
       return user.Players?.length && user.Players?.length > 1
    }

    const removePlayer = () => {
        let updatedList = user.Players?.filter((item) => item.Id !== player.Id)

        setUser(prevUser => ({
            ...prevUser,
            Players: updatedList
        }))
    }

    return (
        <div className='player'>
            <div className='player__headline'>
                <Headline text={'Hráč ' + ord} size={4} />
                {canRemovePlayer()
                    ? <CiCircleRemove className='player__headline__icon' color='#aa0000' title='Odebrat hráče' size='25' onClick={() => removePlayer()} />
                    : ''
                    }
            </div>
            <TextInput
                label='Jméno'
                value={player.Name}
                setValue={e => setPlayerName(e.target.value)}
                type={ETextInputType.INPUT}
                errorMessage={nameError}
                required={true}
            />
            <TextInput
                label='Email'
                value={player.Email || ''}
                setValue={e => setPlayerEmail(e.target.value)}
                type={ETextInputType.INPUT}
                errorMessage={emailError}
                required={ord == 1}
            />
        </div>
        )
}