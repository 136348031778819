export enum EAppState {
    SET_LOGIN_MODAL = 'loginModal',
    SET_TOKEN = 'token',
    SET_SMALL_MENU = 'smallMenu'
}

export interface IAppState {
    [EAppState.SET_LOGIN_MODAL]?: boolean,
    [EAppState.SET_TOKEN]?: string,
    [EAppState.SET_SMALL_MENU]?: boolean,
}