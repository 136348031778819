import { EHistoryPage } from "../../../utils"
import '../subMenu.scss'

type HistoryMenuProps = {
    setPage: any,
    selectedPage: EHistoryPage
}

export const HistoryMenu = ({ setPage, selectedPage }: HistoryMenuProps) => {
    return (
        <div className='sub_menu'>
            <div className={'sub_menu__item' + (selectedPage == EHistoryPage.ABOUT ? ' selected' : '')} onClick={() => setPage(EHistoryPage.ABOUT)}>
                O hře
            </div>
            <div className={'sub_menu__item' + (selectedPage == EHistoryPage.CYPHERS ? ' selected' : '')} onClick={() => setPage(EHistoryPage.CYPHERS)}>
                Šifry
            </div>
            <div className={'sub_menu__item' + (selectedPage == EHistoryPage.STATS ? ' selected' : '')} onClick={() => setPage(EHistoryPage.STATS)}>
                Statistiky
            </div>
            
        </div>
    )
}