import { useEffect, useState } from 'react';
import { IoMdWarning } from "react-icons/io";
import { addPayment, getAdminTeams } from '../../api';
import { AdminMenu, AdminTeam, AdminTeamCancelled, Button, ErrorMessage, Headline, Paragraph, SuccessMessage, TextInput } from '../../components';
import { useAppState } from '../../hooks';
import { AdminPageProps, TUser } from '../../types';
import { EAdminPage, ETextInputType, EUserType } from '../../utils';
import { LoadingPage } from '../loading/LoadingPage';
import '../page.scss';


export const Payments = ({ setPage }: AdminPageProps) => {
    const [loading, setLoadin] = useState(true)
    const [teams, setTeams] = useState<TUser[]>()
    const [vs, setVs] = useState('')
    const [vsError, setVsError] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [selectedAddPaymentTeam, setSelectedAddPaymentTeam] = useState<TUser | undefined>()
    const { state } = useAppState()


    useEffect(() => {
        loadTeams()
    }, [])

    useEffect(() => {
        let regex = /^\d+$/;
        if (vs.length === 8 && vs.startsWith('2401') && regex.test(vs)) {
            let id = Number(vs.slice(-4))
            let team = teams?.find(t => t.Id === id)
            if (team) {
                setSelectedAddPaymentTeam(team)
            }
        }
    }, [vs])

    const loadTeams = () => {
        getAdminTeams({ token: state?.token! }).then(res => {
            if (res.teams) {
                setTeams(res.teams)
            }
            setLoadin(false)
        })
    }

    const setVariableSymbol = (input: string) => {
        setVsError('')
        setError('')
        setSuccess('')
        setSelectedAddPaymentTeam(undefined)
        setVs(input)
    }

    const setPayment = () => {
        setError('')
        setSuccess('')
        let regex = /^\d+$/;
        if (vs.length !== 8 || !vs.startsWith('2401') || !regex.test(vs)) {
            setVsError('Toto není variabilní symbol!')
            return
        }
        let id = Number(vs.slice(-4))
        let team = teams?.find(t => t.Id === id)
        if (!team) {
            setVsError('Tým s tímto variabilním symbolem neexistuje!')
            return
        }
        if (team.Payed) {
            setVsError('Tým má již zaplaceno!')
            return
        }
        addPayment({ idTeam: id, token: state?.token! }).then(res => {
            if (res.success) {
                setSuccess('Platba byla zadána!')
            } else if (res.errorMessage) {
                setError(res.errorMessage)
            } else {
                setError('Došlo k nečekané chybě')
            }
            loadTeams()
        })
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    }

    return (
        
        <div className='page'>
            <AdminMenu setPage={setPage} selectedPage={EAdminPage.PAYMENTS} />
            <ErrorMessage text={error} />
            <SuccessMessage text={success} />
            <Headline text='Platby' size={1} />

            <Headline text='Zadat novou platbu' size={3} />

            <Paragraph>
                <>
            <TextInput
                label='Variabilní symbol'
                setValue={e => setVariableSymbol(e.target.value)}
                errorMessage={vsError}
                type={ETextInputType.INPUT}
                value={vs}
            />   
                    <p>
                        Zadáváte platbu pro tým: <strong>{selectedAddPaymentTeam ? selectedAddPaymentTeam.Name : ''}</strong>
                        {selectedAddPaymentTeam?.Payed ? <IoMdWarning color='red'/> : ''}
                    </p> 
            

                <Button text='Zadat' onClick={setPayment} />

                </>
            </Paragraph>

            <Headline text='Správa týmů' size={3} />
            {
                teams?.filter(t => t.Type === EUserType.TEAM).map(t => <AdminTeam team={t} loadTeams={loadTeams} setError={setError} setSuccess={setSuccess} />)
            }

            <Headline text='Odhlášené týmy' size={3} />
            {
                teams?.filter(t => t.Type === EUserType.CANCELLED).map(t => <AdminTeamCancelled team={t} loadTeams={loadTeams} setError={setError} setSuccess={setSuccess} />)
            }


        </div>
        )
}