import { TUser } from "../types";
import { get, post } from "./axiosClient";

type AddPaymentProps = {
    idTeam: number,
    token: string
}

interface AddPaymentResponse {
    success: boolean,
    errorMessage?: string
}

export const addPayment = async ({ idTeam, token }: AddPaymentProps): Promise<AddPaymentResponse> => {
    return await post({
        data: {},
        params: { teamId: idTeam, newPayment: true },
        path: 'admin/teams/payment',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else {
            return {
                errorMessage: res.data,
                success: false
            }
        }
    });
}

type DeletePaymentProps = {
    idTeam: number,
    token: string
}

interface DeletePaymentResponse {
    success: boolean,
    errorMessage?: string
}

export const deletePayment = async ({ idTeam, token }: DeletePaymentProps): Promise<DeletePaymentResponse> => {
    return await post({
        data: {},
        params: { teamId: idTeam, newPayment: false },
        path: 'admin/teams/payment',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else {
            return {
                errorMessage: res.data,
                success: false
            }
        }
    });
}

type UnregisterTeamProps = {
    idTeam: number,
    token: string
}

interface UnregisterTeamResponse {
    success: boolean,
    errorMessage?: string
}

export const unregisterTeam = async ({ idTeam, token }: UnregisterTeamProps): Promise<UnregisterTeamResponse> => {
    return await post({
        data: {},
        params: { teamId: idTeam, cancel: true },
        path: 'admin/teams/registtation',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else {
            return {
                errorMessage: res.data,
                success: false
            }
        }
    });
}

type ReUnregisterTeamProps = {
    idTeam: number,
    token: string
}

interface ReUnregisterTeamResponse {
    success: boolean,
    errorMessage?: string
}

export const reUnregisterTeam = async ({ idTeam, token }: ReUnregisterTeamProps): Promise<ReUnregisterTeamResponse> => {
    return await post({
        data: {},
        params: { teamId: idTeam, cancel: false },
        path: 'admin/teams/registtation',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else {
            return {
                errorMessage: res.data,
                success: false
            }
        }
    });
}


type GetAdminTeamsProps = {
    token: string
}

type GetTeamsResponse = {
    teams?: TUser[],
    errorMessage?: string
}

export const getAdminTeams = async ({ token}: GetAdminTeamsProps): Promise<GetTeamsResponse> => {
    return await get({
        params: {},
        path: 'admin/teams',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { teams: res.data }
        } else {
            return { errorMessage: res.data }
        }
    })
}