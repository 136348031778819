import { Dispatch } from "react"
import { EAppAction, TAppAction } from "../../types"

export const setAppLoginModalAction = (payload?: boolean) => {
    return async (dispatch: Dispatch<TAppAction>) => {
        dispatch({
            type: EAppAction.SET_LOGIN_MODAL,
            payload
        })
    }
}

export const setAppTokenAction = (payload?: string) => {
    return async (dispatch: Dispatch<TAppAction>) => {
        dispatch({
            type: EAppAction.SET_TOKEN,
            payload
        })
    }
}


export const setAppSmallMenuAction = (payload?: boolean) => {
    return async (dispatch: Dispatch<TAppAction>) => {
        dispatch({
            type: EAppAction.SET_SMALL_MENU,
            payload
        })
    }
}