import { useState } from "react"
import { MdAttachMoney, MdMoneyOff } from "react-icons/md";
import { FaPersonCircleMinus } from "react-icons/fa6";
import { TUser } from "../../../types"
import { ConfirmModal } from "../../modal"
import './AdminTeam.scss'
import { addPayment, deletePayment, generateNewLoginToken, unregisterTeam } from "../../../api";
import { useAppState } from "../../../hooks";

type AdminTeamProps = {
    team: TUser,
    loadTeams: any,
    setError: any,
    setSuccess: any
}

export const AdminTeam = ({ team, loadTeams, setError, setSuccess }: AdminTeamProps) => {
    const [addPaymentConfirmModalVisible, setAddPaymentConfirmModalVisible] = useState(false)
    const [deletePaymentConfirmModalVisible, setDeletePaymentConfirmModalVisible] = useState(false)
    const [unregisterTeamConfirmModalVisible, setUnregisterTeamConfirmModalVisible] = useState(false)
    const { state } = useAppState()

    const addPaymentAction = () => {
        setError('')
        setSuccess('')
        setAddPaymentConfirmModalVisible(false)
        addPayment({ idTeam: team.Id, token: state?.token! }).then(res => {
            if (res.success) {
                setSuccess('Platba byla zadána!')
            } else if (res.errorMessage) {
                setError(res.errorMessage)
            } else {
                setError('Došlo k nečekané chybě')
            }
            loadTeams()
        })
    }

    const deletePaymentAction = () => {
        setError('')
        setSuccess('')
        setDeletePaymentConfirmModalVisible(false)
        deletePayment({ idTeam: team.Id, token: state?.token! }).then(res => {
            if (res.success) {
                setSuccess('Platba byla zrušena!')
            } else if (res.errorMessage) {
                setError(res.errorMessage)
            } else {
                setError('Došlo k nečekané chybě')
            }
            loadTeams()
        })
    }

    const unregisterTeamAction = () => {
        setError('')
        setSuccess('')
        setUnregisterTeamConfirmModalVisible(false)
        unregisterTeam({ idTeam: team.Id, token: state?.token! }).then(res => {
            if (res.success) {
                setSuccess('Týmu byla zrušena účast!')
            } else if (res.errorMessage) {
                setError(res.errorMessage)
            } else {
                setError('Došlo k nečekané chybě')
            }
            loadTeams()
        })
    }

    const refirectToWebsystem = (id: number) => {
        generateNewLoginToken({ id: id, token: state!!.token!! }).then((response) => {
            if (response.token) {
                window.open(`https://www.sifrovacihra.cz/websystem/index.html?loginToken=${response.token}`,
                    'newwindow',
                    'width=500,height=400'
                )
            } else {
                setError(response.errorMessage || 'Vygenerování přihlašovacího odkazu se nezdařilo :(')
            }
        }).catch(() => {
            setError('Vygenerování přihlašovacího odkazu se nezdařilo :(')
        })
    }

    return (
        <>

            <div className='admin_team'>
                <div className='admin_team__payment'>
                    {team.Payed ?
                        <div className='admin_team__payment__remove' title='Zrušit platbu' onClick={() => setDeletePaymentConfirmModalVisible(true)}>
                            <MdMoneyOff size={25} />
                        </div>
                        : <div className='admin_team__payment__add' title='Zadat platbu' onClick={() => setAddPaymentConfirmModalVisible(true)}>
                            <MdAttachMoney size={25} />
                        </div>
                    }
                </div>
                <div className='admin_team__unregister' title='Odregistrovat tým' onClick={() => setUnregisterTeamConfirmModalVisible(true)}>
                    <FaPersonCircleMinus size={25} />
                </div>
                <div className='admin_team__name'>
                    {team.Name}
                </div>


            </div>
            <ConfirmModal
                headline='Zadat platbu'
                text={`Opravdu chcete zadat platbu týmu ${team.Name}?`}
                onCloseClick={() => setAddPaymentConfirmModalVisible(false)}
                visible={addPaymentConfirmModalVisible}
                onConfirm={() => addPaymentAction()}
            />

            <ConfirmModal
                headline='Zrušit platbu'
                text={`Opravdu chcete zrušit platbu týmu ${team.Name}?`}
                onCloseClick={() => setDeletePaymentConfirmModalVisible(false)}
                visible={deletePaymentConfirmModalVisible}
                onConfirm={() => deletePaymentAction()}
            />

            <ConfirmModal
                headline='Zrušit registraci'
                text={`Opravdu chcete zrušit registraci týmu ${team.Name}?`}
                onCloseClick={() => setUnregisterTeamConfirmModalVisible(false)}
                visible={unregisterTeamConfirmModalVisible}
                onConfirm={() => unregisterTeamAction()}
            />
        </>
    )
}