import { JsxElement } from 'typescript';
import './form.scss';

type ButtonProps = {
    text: string,
    onClick: any,
    disabled?: boolean,
    secondary?: boolean,
    icon?: JSX.Element,
}

export const Button = ({ text, onClick, disabled, secondary, icon }: ButtonProps) => {
    return (
        <div className={'button' + (secondary ? ' secondary' : '')}>
            <button
                className={'button__button' + (secondary ? ' secondary' : '')}
                onClick={onClick}
                disabled={disabled}
            >
                <>
                    {text} {icon ? <div className='button__button__icon'>{icon}</div> : <></>}
                </>
            </button>
        </div>
    )
}