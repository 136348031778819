import { useState } from "react"
import { CypherMessages, OrgMessages } from "../../components"
import { EGame, EHistoryPage } from "../../utils"
import { About } from "./About"
import { CyphersPage } from "./CyphersPage"
import { Stats } from "./Stats"
import './history.scss'

export const History = () => {
    const [page, setPage] = useState(EHistoryPage.ABOUT)
    const [year, setYear] = useState(EGame.ZKR2024)

    const getPage = () => {
        switch (page) {
            case EHistoryPage.ABOUT: return <About setPage={setPage} year={year} />
            case EHistoryPage.CYPHERS: return <CyphersPage setPage={setPage} year={year} />
            case EHistoryPage.STATS: return <Stats setPage={setPage} year={year} />
        }
    }

    if (page === EHistoryPage.STATS) {
        return (
            <>
                {getPage()}
            </>
        )
    }

    return (
        <>
            <div className='page_and_news'>
                {getPage()}
                <div className='news_boxes'>
                    <div className='news_boxes__box scroll'>
                        <CypherMessages year={year} />
                    </div>
                </div>
            </div>
            <div className= 'mobile_only'>
                <div className='page no-min-height'>
                    <CypherMessages year={year } />
                </div>
            </div>
        </>
    )
}