type CheckBoxProps = {
    label: string,
    checked: boolean,
    setChecked: any
}

export const CheckBox = ({ label, checked, setChecked }: CheckBoxProps) => {
    return (
        <div className='checkbox' onClick={() => setChecked(!checked)}>
            <input
                type="checkbox"
                className='checkbox__input'
                checked={checked}
            />
            <span className="checkbox__checkmark"></span>
            <div className='checkbox__label'>{label }</div>
        </div>
        )
}