import { useEffect, useState } from "react"
import { getCypherMessages, getForumMessages } from "../../api"
import { ForumMessage, Headline, Loading, Paragraph } from "../../components"
import { cyphers2024 } from "../../resources"
import { TCypher, TForumMessage } from "../../types"
import { EGame } from "../../utils"

type CypherMessagesProps = {
    year: EGame
}

export const CypherMessages = ({ year }: CypherMessagesProps) => {
    const [messages, setMessages] = useState<TForumMessage[]>([])
    const [cyphers, setCyphers] = useState<TCypher[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        switch (year) {
            case EGame.ZKR2024:
                setCyphers(cyphers2024)
        }
    }, [year])

    useEffect(() => {
        if (cyphers.length > 0) {
            reloadMessages()
        }
    }, [cyphers])

    const reloadMessages = () => {
        getCypherMessages().then(res => {
            if (res.messages) {
                let newMessages = res.messages.slice(0, 10)
                setMessages(newMessages.map(m => ({
                    ...m, // Zachová původní vlastnosti zprávy
                    Headline: getHeadline(m) // Aktualizuje `Headline`
                })))

            }
            setLoading(false)
        })
    }

    function getHeadline(m: TForumMessage): string {
        if (m.Forum) {
            let c = cyphers.find(c => c.Id.toString() == m.Forum)
            if (c) {
                return `${c.Id}-${c.name}`
            }
        }
        return ""
    }

    return (
        <div className='org_messages' onClick={() => reloadMessages()} >
            <Headline text='Nejnovější komentáře' size={3} />
            {loading ? <Loading size={40} /> :
                <div className='org_messages__messages'>
                    {
                        messages.map(m =>
                            <ForumMessage message={ m} />
                        )
                    }
                </div>
            }
        </div>
    )
}