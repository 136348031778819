import './page.scss'

type HeadlineProps = {
    text: string,
    size: number,
}

export const Headline = ({ text, size }: HeadlineProps) => {
    return (
        <div className='headline'>
            {{
                1: <h1>{text}</h1>,
                2: <h2>{text}</h2>,
                3: <h3>{text}</h3>,
                4: <h4>{text}</h4>
            }[size] }
        </div>
        )
}