import { TUser } from "../types"
import { get } from "./axiosClient"

type GetTeamsResponse = {
    teams?: TUser[],
    errorMessage?: string
}

export const getTeams = async (): Promise<GetTeamsResponse> => {
    return await get({
        params: {},
        path: 'teams'
    }).then((res) => {
        if (res.status === 200) {
            return { teams: res.data }
        } else {
            return { errorMessage: res.data }
        }
    })
}