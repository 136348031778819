import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getId, loginUser, sendForgottenPassword } from "../../../api"
import { useAction, useAppState } from "../../../hooks"
import { EAppState } from "../../../types"
import { COOKIE_TOKEN, EPage, ETextInputType, hash } from "../../../utils"
import { Button, Loading, TextInput } from "../../../components"
import { Modal } from "../Modal"
import '../modal.scss'
import Cookies from "js-cookie"

export const LoginModal = () => {
    const { state } = useAppState()
    const { setAppLoginModalAction, setAppTokenAction } = useAction()
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [forgottenPassword, setForgottenPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(false)
        setLogin("")
        setLoginError("")
        setPassword("")
        setPasswordError("")
        setErrorMessage("")
        setEmail("")
        setEmailError("")
        setForgottenPassword(false)
    }, [state?.[EAppState.SET_LOGIN_MODAL]])

    const initLogin = () => {
        setLoading(true)
        getId({ login: login, password: '' }).then((id) => {
            if (!id) {
                setErrorMessage('Došlo k neočekávané chybě, v případě přetrvajících problémů kontaktujte organizátory.')
                setLoading(false)
                return
            } else if (id == '-1') {
                setLoading(false)
                setErrorMessage('Pro zadané jméno nebyl nalezen žádný tým.')
                return
            } else
                doLogin(id)
        }).catch(() => {
            setLoading(false)
            setErrorMessage('Došlo k neočekávané chybě, v případě přetrvajících problémů kontaktujte organizátory.')
        })
    }

    const doLogin = (id: string) => {

        const encryptedPswd = hash(id + password)
        loginUser({ login: login, password: encryptedPswd }).then((response) => {
            setLoading(false)
            if (response.token) {
                setAppTokenAction(response.token)
                Cookies.set(COOKIE_TOKEN, response.token)
                navigate(EPage.REGISTRATION)
                onCloseClick()
            } else {
                setErrorMessage(response.errorMessage || 'Přihlášení se nezdařilo. Ujistěte se, že zadáváte správný název týmu a heslo, v případě problémů kontaktujte organizátory.')
            }
        }).catch(() => {
            setLoading(false)
            setErrorMessage('Přihlášení se nezdařilo. Ujistěte se, že zadáváte správný název týmu a heslo, v případě problémů kontaktujte organizátory.')
        })
    }

    const sendPassword = () => {
        setLoading(true)
        sendForgottenPassword({ login: login, email: email }).then((res) => {
            setLoading(false)
            if (res.success) {
                setErrorMessage('Postup k obnově hesla vám byl odeslán na email.')
            } else {
                setErrorMessage(res.errorMessage!!)
            }
        }).catch(() => {
            setLoading(false)
            setErrorMessage('Obnova hesla se nezdařila, kontaktujte prosím organizátory emialem.')
        })
    }

    const newRegistration = () => {
        navigate(EPage.REGISTRATION)
        onCloseClick()
    }

    const onCloseClick = () => {
        setAppLoginModalAction(false)
    }

    const onLoginClick = () => {
        setLoginError('')
        setPasswordError('')
        setEmailError('')
        if (login.length < 1) {
            setLoginError('Přihlašovací jméno musí být vyplněno!')
        }
        if (password.length < 1) {
            setPasswordError('Heslo musí být vyplněno!')
        }
        if (password.length > 0 && login.length > 0) {
            initLogin()
        } else {
            setErrorMessage('Vyplňte přihlašovací jméno a heslo!')
        }
    }

    const sendPasswordClick = () => {
        setLoginError('')
        setPasswordError('')
        setEmailError('')
        if (login.length < 1) {
            setLoginError('Přihlašovací jméno musí být vyplněno!')
        }
        if (email.length < 1) {
            setPasswordError('Email 1. hráče musí být vyplněn!')
        }
        if (email.length > 0 && login.length > 0) {
            sendPassword()
        } else {
            setErrorMessage('Vyplňte přihlašovací jméno a email 1. hráče!')
        }
    }

    return (
        <Modal visible={state?.[EAppState.SET_LOGIN_MODAL]} headline='Přihlášení' closeClick={onCloseClick} error={errorMessage}>
            <>
                {loading ? <Loading size={25} />
                    :
                    <>
                        <TextInput
                            label='Tým'
                            required={true}
                            type={ETextInputType.INPUT}
                            errorMessage={loginError}
                            value={login}
                            setValue={e => setLogin(e.target.value)}
                        />

                        {forgottenPassword
                            ?
                            <TextInput
                                label={'Email 1. hráče'}
                                required={true}
                                type={ETextInputType.INPUT}
                                errorMessage={emailError}
                                value={email}
                                setValue={e => setEmail(e.target.value)}
                            />
                            :
                            <TextInput
                                label={'Heslo'}
                                required={true}
                                type={ETextInputType.PASSWORD}
                                errorMessage={passwordError}
                                value={password}
                                setValue={e => setPassword(e.target.value)}
                            />
                        }


                        <Button
                            text={forgottenPassword ? 'Obnovit heslo' : 'Přihlásit se'}
                            onClick={forgottenPassword ? sendPasswordClick : onLoginClick}
                        />

                        <div>
                            Ještě nemáte registrovaný tým? Přihlašte se <span className='href' onClick={newRegistration}>zde</span>.
                        </div>
                        <div>
                            <span className='href' onClick={() => setForgottenPassword(!forgottenPassword)}>Zapomněli jste heslo?</span>
                        </div>
                    </>
                }
            </>
        </Modal>
    )
}