import Cookies from "js-cookie";
import { EAppAction, EAppState, IAppState, TAppAction } from "../../types";
import { COOKIE_TOKEN } from "../../utils";

export const appInitialState: IAppState = {
    loginModal: false,
    token: Cookies.get(COOKIE_TOKEN),
    smallMenu: false,
}

export const appReducer = (state = appInitialState, action: TAppAction) => {
    switch (action.type) {
        case EAppAction.SET_LOGIN_MODAL:
            return { ...state, [EAppState.SET_LOGIN_MODAL]: action.payload }
        case EAppAction.SET_TOKEN:
            return { ...state, [EAppState.SET_TOKEN]: action.payload }
        case EAppAction.SET_SMALL_MENU:
            return { ...state, [EAppState.SET_SMALL_MENU]: action.payload }
        default:
            return state
    }
}