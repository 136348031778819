import axios from 'axios'

//const local = 'http://localhost:53471/api/'
const local = 'https://www.sifrovacihra.cz/api/'
const production = 'https://www.sifrovacihra.cz/api/'

const host = window.location.href.includes('localhost') ? local : production

const actualGame = 'ZKR2024'

interface IAxiosPostParams {
    data: any,
    params: any,
    path: string,
    token?: string,
    game?: string
}

export const post = async ({data, params, path, token, game }: IAxiosPostParams): Promise<any> => {

    let config = {
        params,
        headers: {
            'Game': game ? game : actualGame,
            'Auth': token,
            'Content-Type': 'application/json'
        },


    };

    return await axios.post(host + path, data, config)
        .then((res) => {
            if (res) {
                return {
                    status: res.status,
                    data: res.data
                }
            }
            return {
                status: 400,
                data: undefined
            }
        })
        .catch(
            e => {
                console.log(e)
                return {
                    status: e.response?.status,
                    data: e.response?.data
                }
            }
        )
}

interface IAxiosGetParams {
    params: any,
    path: string,
    token?: string,
    game?: string
}
export const get = async ({ params, path, token, game }: IAxiosGetParams): Promise<any> => {

    let config = {
        params,
        headers: {
            'Game': game ? game : actualGame,
            'Auth': token
        },


    };

    return await axios.get(host + path, config)
        .then((res) => {
            if (res) {
                return {
                    status: res.status,
                    data: res.data
                }
            }
            return {
                status: 400,
                data: undefined
            }
        })
        .catch(
            e => {
                console.log(e)
                console.log(e.status)
                return {
                    status: e.response?.status,
                    data: e.response?.data
                }
            }
        )
}