import { FaFacebook } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { BsSignpostSplit } from "react-icons/bs";
import './footer.scss'

export const Footer = () => {
    return (
        <div className='footer'>
            <div>
                <a href="https://www.facebook.com/profile.php?id=100094676957308" target='_blank'><div className='footer__item'> <FaFacebook size={30} className='footer__item__icon'/>Zkratka </div></a>
            </div>
            <div>
                <a href="mailto:zkratka.sifrovacihra@gmail.com" target='_blank'><div className='footer__item'> <BiLogoGmail size={30} className='footer__item__icon' />zkratka.sifrovacihra@gmail.com </div></a>
            </div>
            <div>
                <a href="https://www.sifrovacihra.cz" target='_blank'><div className='footer__item'> <BsSignpostSplit size={30} className='footer__item__icon' />Rozcestník</div></a>
            </div>
        </div>
        )
}