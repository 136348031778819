import { Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Document } from 'react-pdf';

type PdfProps = {
    url: string
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const Pdf = ({ url }: PdfProps) => {
    return (
        <div className="pdf">
            <Document file={url}>
                <Page
                    pageNumber={1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                />
            </Document>
        </div>
    )
}