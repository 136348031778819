import { ReactChild } from "react"
import './page.scss'

type ParagraphProps = {
    children: ReactChild,
    centered?: boolean
}

export const Paragraph = ({ children, centered }: ParagraphProps) => {
    return (
        <>
            <div className={'paragraph' + centered ? '__centered' : ''}>
                 {children }
            </div>
        </>
    )
}