import { useEffect, useState } from "react"
import { getForumMessages } from "../../api"
import { ForumForm, ForumMessage, Headline, Loading } from "../../components"
import { TForumMessage } from "../../types"
import { EForumEndpoint, EGame } from "../../utils"
import '../page.scss'
import './forum.scss'

export const Forum = () => {
    const [messages, setMessages] = useState<TForumMessage[]>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        reloadMessages()
    },[])

    const reloadMessages = () => {
        getForumMessages({
            forum: EGame.ZKR2024
        }).then(res => {
            if (!res.errorMessage) {
                setMessages(res.messages)
            }
            setLoading(false)
        })
    }

    return (
        <div className='page'>
            <>
                <Headline text='Diskuze' size={1} />
                
                <ForumForm forum={EGame.ZKR2024} reloadMessage={reloadMessages} endpoint={EForumEndpoint.FORUM } />

                {loading ? <Loading size={60} /> :
                    <div className='forum_messages'>
                        {
                            messages?.map(m => <ForumMessage message={m} />)
                        }
                    </div>
               }

                
            </>
        </div>
        )
}