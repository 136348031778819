import { useEffect, useState } from 'react';
import { getForumMessages } from "../../api";
import { CypherRating, ForumForm, ForumMessage, Headline, Loading, Paragraph, Pdf} from "../../components"
import { TCypher, TForumMessage } from "../../types";
import { EForumEndpoint } from "../../utils";
import { Help } from "./Help";


type CypherProps = {
    cypher: TCypher
}

export const Cypher = ({ cypher }: CypherProps) => {
    const [loading, setLoading] = useState(true)
    const [cypherId, setCypherId] = useState(0)

    const [messages, setMessages] = useState<TForumMessage[]>()

    useEffect(() => {
        console.log("change: " + cypher.Id)
        setCypherId(cypher.Id)
        reloadMessages()
        setLoading(true)
    }, [cypher])


    const reloadMessages = () => {
        getForumMessages({
            forum: cypher.Id.toString()
        }).then(res => {
            if (!res.errorMessage) {
                setMessages(res.messages)
            }
        }).finally(() => {
            setLoading(false)
        }
        )
    }

    if (!cypher) {
        return <></>;
    }

    return (
        <div className="cypher">
            <Headline text={"Šifra " + cypherId + ": " + cypher.name} size={3} />
            <CypherRating cypherId={cypherId }/>
            {cypher.message ? <Paragraph>{ cypher.message}</Paragraph> : <></> }
            
             <div className="cypher__detail">
                {cypher.url
                    ? (
                        <div>Zadání v PDF si můžete stáhnout <a href={cypher.url} target="_blank">zde</a>.</div>
                    )
                    : ""}
                {cypher.helps ?
                    <div className='cypher__detail__helps'>
                        {
                            cypher.helps.map(h => <Help help={h}/>)
                        }
                    </div>   
                    : <></>}

                <Headline text='Komentáře k šifře' size={3}/>
                <div className='comments'>
                    <ForumForm forum={cypherId.toString()} reloadMessage={reloadMessages} endpoint={EForumEndpoint.FORUM} />

                    {loading ? <Loading size={60} /> :
                        <div className='forum_messages'>
                            {
                                messages?.map(m => <ForumMessage message={m} />)
                            }
                        </div>
                    }
                </div>
                
              </div>           
        </div> 
    )
}