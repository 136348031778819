import { Headline, Paragraph } from '../../components';
import '../page.scss';

export const Info = () => {
    return (
        <div className='page'>
            <Headline text='Pravidla' size={1} />

            <Headline text='Úvodem' size={3} />
            <Paragraph>Píšeme-li o něčem, že to není vhodné a podobně, nedělejte to. Nechceme nikoho diskvalifikovat nebo zakazovat účast, ale některé věci, 
                i když vám to třeba tak nepřijde, kazí hru ostatním týmům nebo orgům. Nejste-li si správným vyložením pravidel jistí či byste z nějakého důvodu 
                potřebovali výjimku, napište nám do diskuze nebo na mail. Není problém se dohodnout, problém je nedodržovat a hádat se až dodatečně. 
                Po minulé zkušenosti speciálně: nezasahujte nám prosím nijak do hry bez našeho vědomí. 
                Jestli něco takového uvidíme, takový tým bude velmi pravděpodobně bez milosti diskvalifikován bez nároku na vrácení startovného. 
                Pokud máte nápad jak hru zlepšit, chcete do hry umístit vlastní šifru a podobně, kontaktujte nás a můžeme se pobavit o možnostech.</Paragraph>

            <Headline text='Start a cíl' size={3} />
            <Paragraph>
                <ul>
                    <li>Hra bude začínat v sobotu 12. října 2024 v 8:30, vyzvednout věci bude potřeba o něco dříve. </li>   
                    <li>Místo startu se dozvíte nejpozději v září a po zaplacení startovného na stránce svého týmu. </li>
                    <li>Konec hry bude tentýž den ve 21:00 na místě, které se dozvíte během hry.</li>

                </ul>            
            </Paragraph>

            <Headline text='Podmínky účasti' size={3} />
            <Paragraph>
                <ul>
                    <li> Hra je určena pro týmy o dvou až pěti členech. Je možná i účast jednotlivců, začátečníkům to však silně nedoporučujeme. Pro dostatečně zkušené a sebevědomé jedince to ale může být dobrá výzva. </li>
                    <li> Za člena týmu je považována osoba napsaná na soupisce podílející se na hře. Nikdo jiný než členové týmu by neměl s průchodem hrou týmu pomáhat. 
                        Nebude nám tedy vadit, budete-li mít s sebou kočárek s malým dítětem nebo šifru najde pes, není ale vhodné, aby bylo v jednom týmu sedm dětí, které se v luštění střídají, 
                        nebo za vás šifru luštili přátelé na telefonu.</li>
                    <li> <strong>Startovné za hru je 250 Kč při platbě do 8. 9. 2024 a 300 Kč od 9. 9. do 30. 9. 2024. Rozhodující je datum připsání částky na účet. Registrace v říjnu již nebude možná.</strong></li>
                    <li>Berte prosím vyhlášené termíny na vědomí a pokuste se je dodržet. Nechceme nikoho připravit o zážitek, takže přidání dalšího týmu v říjnu teoreticky možné je (po dohodě s organizátory), komplikuje nám to však přípravy. (Zároveň se může stát, že při pozdní přihlášce již budeme muset nějaké týmy odmítnout) </li>

                </ul>
            
            </Paragraph>

            <Headline text='Systém hry' size={3} />

            <Paragraph>
               <>
               Myslíme si, že na šifrovačkách jsou hlavní šifry a jejich luštění. Většina šifrovaček ale určuje pořadí podle posledního dosaženého stanoviště a 
               týmy jsou hlavně ke konci hry motivované spíše brát nápovědy než se snažit luštit. Na Zkratce používáme bodový systém, který bere ohled hlavně na to, kolik šifer jste vyluštili 
               a s jakým množstvím nápověd. Můžete se umístit lépe, než týmy, které došly dál, příchod do cíle u nás není kritérium pro umístění. 
               Samozřejmě ale budeme rádi, když nás třeba i po konci hry přijdete pozdravit.      
                

                <ul>
                <li> Hra bude fungovat na bodovém systému. Za každou vyluštěnou šifru, splněnou aktivitu či bonusovou šifru tým obdrží určitý počet bodů. Tento počet bude hráči znám nejpozději při obdržení šifry. </li>
                <li> Nápovědy budou týmu k dispozici okamžitě po zadání kódu stanoviště a to jak obyčejné, tak absolutní. Za každou použitou nápovědu se snižuje maximální možný bodový zisk za danou šifru, za absolutní je tento pak nula bodů. Letos bude opět fungovat typ nápovědy nazvaný "skoroabsolutka" - ten by měl týmu sdělit celý postup řešení šifry, ale tým ho musí provést sám. Pokud se mu to povede, obdrží nějaký malý počet bodů, pokud ne, stále mu zbývá absolutní nápověda. </li>
                <li> Zadáním cílového hesla nekončí hra!! Bonusy je možné odevzdávat až do ukončení hry, tj. 21:00. </li>
                <li> Pořadí týmů na konci hry bude určeno primárně počtem bodů, při shodě potom časem posledního bodového zisku (dříve je lépe).</li>
                </ul>
                </>
            </Paragraph>

            <Headline text='Zakázané a povolené postupy' size={3} />

            <Paragraph>
                <ul>
                    <li>Tým se smí během hry přesouvat pouze pomocí vlastních nohou, kočárku s lidským pohonem nebo MHD. (žádná kola, auta, koloběžky, brusle,..) </li>
                    <li>Tým se smí v průběhu hry libovolně dělit. (Je tedy možná i například účast části týmu na dálku)</li>
                    <li>Je zakázáno následovat jiné týmy bez znalosti polohy dalšího stanoviště.</li>
                    <li>Najdete-li omylem šifru s vyšším číslem než právě hledáte, je zakázáno ji sbírat. </li>
                    <li>Tým se smí ptát kolemjdoucích na věci encyklopedické povahy (typu "Jaké plody má dub?" nebo dotazy navigačního charakteru), neměli by mu však radit, jak naložit s šifrou.</li>
                    <li>Je povoleno si hledat informace na internetu (ne však ptát se kamarádů přes internet na řešení). Použití GLM typu chatGPT nezakazujeme, rádi si po hře poslechneme, k čemu vám to bylo.</li>
                    <li>Je zakázána jakákoliv spolupráce týmů, žádné slučování, výměny členů, pomáhání si, ...(samozřejmě se to týká jen šifer, pomoci konkurenci například při přenosu kočárku nebo při zranění člena můžete)</li>
                    <li>Tým postupuje hrou v souladu s platnými zákony ČR a podle zásad fair-play. Pokud vás řešení šifry nabádá k porušení zákona, máte to špatně.</li>
                </ul>
            </Paragraph>

            <Headline text='Šifry' size={3} />

            <Paragraph>
                <ul>
                    <li>Po vyzvednutí šifry se vzdalte od stanoviště (ideálně dále, než pár metrů), ať nenapovídáte pozici nebo ji naopak neblokujete </li>
                    <li>Nesedejte si příliš blízko k ostatním týmům! Pokud jiný tým slyší, co si povídáte, nebo vy slyšíte je, jste moc blízko. Minimální vzdálenost by měla být cca 10 metrů pokud mluvíte potichu. 
                        Pokud vám nedaleko sedící tým, který tam byl dříve, řekne, že jste moc blízko, nehádejte se s ním a odejděte dál, pár metrů navíc vám nic neudělá a slyšet pořád "myšlenky" sousedního týmu může být opravdu nepříjemné, každý na to chce přece přijít sám. </li>
                    <li>Dochází-li na stanovišti šifry, dejte nám prosím vědět. Je-li k dispozici poslední, pak není povoleno ji sebrat, ale tým by si ji měl opsat/ofotit a nechat k dispozici ostatním </li>
                    <li>Pokud si myslíte, že jste na správném místě (pozor na upřesnítka!), ale nemůžete šifru najít, zavolejte organizátorům. </li>
                    <li>Stanoviště od sebe nikdy nejsou vzdálena více než 2 km vzdušnou čarou </li>
                    <li>Váháte-li nad interpretací tajenky, rozhodněte se podle mapy.cz</li>
                    <li>Kurzívou jsou psané pokyny (neobsahují tedy šifru)</li>
                    <li>Nehledejte šifry ve způsobech předání, tiskařských či výrobních nedokonalostech a podobně</li>
                </ul>
            </Paragraph>

            <Headline text='Doporučené vybavení na hru' size={3} />

            <Paragraph>
                <ul>
                    <li>Nabitý mobil/tablet s přístupem k internetu, ideálně s powerbankou </li>
                    <li>mapy.cz (může se hodit offline verze kvůli signálu, ale zase pamatujte, že vyhledávání funguje lépe se zaplými daty)</li>
                    <li>Své oblíbené šifrovací pomůcky (nějaké od nás dostanete, ale může být fajn mít více, například ty od <a href="http://www.napalmne.cz/pomucky/" className='href'>NaPALMU</a> nebo <a href="http://chlyftym.cz/pomucky/" className='href'>Chlýftýmu</a>, může se hodit i aplikace "Šifrovací pomůcky" do mobilu) </li>
                    <li>Může se hodit čtečka QR kódů, není nutná</li>
                    <li>Průhledná folie a něco, čím na ni lze psát</li>
                    <li>Barvičky (pastelky, fixy nebo další alternativy)</li>
                    <li>Nůžky</li>
                    <li>Pravítko</li>
                    <li>Izolepu/lepidlo/čerstvou smůlu</li>
                    <li>Dobrá obuv, teplé oblečení dle uvážení, zásoby jídla a pití, může se hodit něco na sezení (stolička, podsedák, karimatka,atd.) </li>
                    <li>Zdroj světla, doporučujeme čelovku (hra končí po setmění) </li>
                </ul>
            </Paragraph>
        </div>
    )
}