import { useEffect, useState } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { getUser, getUserDetail, registerUser, saveUser } from '../../api';
import { Button, ErrorMessage, Headline, Paragraph, PaymentQRCode, Player, Spacer, SuccessMessage, TextInput, WebsystemLoginQRCode } from '../../components';
import { useAction, useAppState } from '../../hooks';
import {TUser } from '../../types'
import { ETextInputType, EUserType, hash, isValidPhone, isValidPlayer } from '../../utils';
import { LoadingPage } from '../loading/LoadingPage';
import '../page.scss';
import './registration.scss';

export const Registration = () => {
    const { state } = useAppState()
    const { setAppTokenAction } = useAction() 
    const [user, setUser] = useState<TUser>(
        {
            Name: '',
            Id: -1,
            Password: '',
            Phone: '',
            Type: EUserType.TEAM,
            Players: [{ Name: '', Id: 1 }, { Name: '', Id: 2 }]
        })
    const [password, setPassword] = useState('')
    const [nameError, setNameError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [showErrors, setShowErrors] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [newRegistration, setNewRegistration] = useState(false)
    const [loading, setLoading] = useState(true)
    const [closedRegistration, setClosedRegistration] = useState(true)

    useEffect(() => {
        if (!state?.token) {
            setNewRegistration(true)
            setUser({
                Name: '',
                Id: -1,
                Password: '',
                Phone: '',
                Type: EUserType.TEAM,
                Players: [{ Name: '', Id: 1 }, { Name: '', Id: 2 }]
            })
            setLoading(false)
        } else {
            updateUser()
            setClosedRegistration(false)
        }

        const queryParameters = new URLSearchParams(window.location.search)

        if (queryParameters && !state?.token) {
            let adminRegistration = queryParameters.get("adminRegistration")
            if (adminRegistration == "closed2024") {
                setClosedRegistration(false)
                return
            }
        }
        
    }, [state?.token])


    useEffect(() => {
        if (state?.token && user.Id > -1 && newRegistration) {
            savePassword()
        }
    }, [user.Id, state?.token])

    useEffect(() => {
        if (state?.token && user.Id > -1 && user.Password?.length > 0) {
            saveForm(state?.token)
        }
    }, [user.Password])

    const setName = (newName: string) => {
        setUser(prevUser => ({
            ...prevUser,
            Name: newName
        }))
    }

    const setPhone = (newPhone: string) => {
        setUser(prevUser => ({
            ...prevUser,
            Phone: newPhone
        }))
    }

    const addPlayer = () => {
        let updatedList = [...user.Players || [], { Name: '', Id: (user.Players && user.Players.length > 0) ? (Math.max(...user.Players.map(p => p.Id)) + 1) : 1 }]

        setUser(prevUser => ({
            ...prevUser,
            Players: updatedList
        }))
    }

    const registerButtonClick = () => {
        setNameError('')
        setPasswordError('')
        setPhoneError('')
        setShowErrors(false)
        setError('')
        let valid = true
        if (user.Name.length < 1) {
            valid = false
            setNameError("Vyplňte název týmu")
        }
        if (newRegistration) {
            if (password && password.length < 1) {
                valid = false
                setPasswordError("Vyplňte heslo")
            } else if (password.length < 5) {
                valid = false
                setPasswordError("Heslo musí být dlouhé alespoň 5 znaků")
            }
        }
        if (user.Phone.length < 1) {
            valid = false
            setPhoneError("Vyplňte kontaktní telefon")
        } else if (!isValidPhone(user.Phone)) {
            valid = false
            setPhoneError("Kontaktní telefon nemá správný tvar")
        }
        user.Players?.forEach((p, i) => valid = valid && isValidPlayer(p, i))

        if (valid) {
            if (newRegistration) {
                doRegister()
            } else {
                if (password.length > 0) {
                    savePassword()
                } else {
                    saveForm(state?.token!)
                }
            }
        } else {
            setError('Ve formuláři se vyskytly chyby.')
            setShowErrors(false)
            setTimeout(() => setShowErrors(true), 0);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const doRegister = () => {
        registerUser({ name: user.Name }).then((response) => {
            if (response.token) {
                getNewId(response.token)
            } else {
                let resError = response.errorMessage
                if (resError) {
                    setError(resError)
                } else {
                    setError('Při přihlášení nastala neočekávaná chyba.')
                }
            }
        })
    }

    const getNewId = (token: string) => {
        getUser({ token: token }).then((response) => {
            let id = response.user?.Id
            if (id && id > -1) {
                setUser(prevUser => ({
                    ...prevUser,
                    Id: id || -1
                }))
                setAppTokenAction(token)
            } else {
                let resError = response.errorMessage
                if (resError) {
                    setError(resError)
                } else {
                    setError('Při přihlášení nastala neočekávaná chyba.')
                }
            }
        })
    }

    const savePassword = () => {
        const encryptedPswd = hash(user.Id + password)
        setUser(prevUser => ({
            ...prevUser,
            Password: encryptedPswd
        }))
    }

    const saveForm = (token: string) => {
        saveUser({ user: user, token: token }).then((res) => {
            if (res.success) {
                if (newRegistration) {
                    setSuccess('Váš tým byl úspěšně registrovaný.')
                    updateUser()
                } else {
                    setSuccess('Údaje byly uloženy.')
                }
            } else {
                setError('Při přihlášení nastala neočekávaná chyba.')
            }
        })
    }

    function updateUser() {
        getUserDetail({ token: state?.token! }).then((response) => {
            let id = response.user?.Id
            if (user && id && id > -1) {
                setUser(response.user!)
                setNewRegistration(false)
            }
            setLoading(false)
        })
    }

    if (loading) {
        return <LoadingPage />
    }

    if (closedRegistration) {
        return (
            <div className='page'>
                <Headline text={state?.token ? 'Detail týmu' : 'Registrace'} size={1} />
                <Paragraph>
                    <>
                        <p>
                            Registrace na letošní ročník již bohužel skončila :(
                        </p>
                    </>
                </Paragraph>
            </div>
            )
    }
    

    return (
        <>
            <div className='page'>
                <div className='messages'>
                    {error ? <ErrorMessage text={error} /> : <></>}
                    {success ? <SuccessMessage text={success} /> : <></>}
                </div>
                <Headline text={state?.token ? 'Detail týmu' : 'Registrace'} size={1} />

                {state?.token && user.Id > 0
                    ?

                    <Paragraph>
                        <>
                            <p>
                                Zaplacené startovné: {user.Payed ? <span className='payment__yes'>ANO</span> : <span className='payment__no'>NE</span>}
                            </p>
                            {user.Payed
                                ?<>
                                    <p>
                                        Hra letos začne v parku u ZŠ Dolní Měcholupy, prezence bude probíhat na tomto místě: <a href="https://en.mapy.cz/s/magusedopu" className='href' target="_blank">mapy.cz</a>. Poslední infromace najdete zde: <a href="https://www.sifrovacihra.cz/zkratka/posledni_informace.pdf" className='href' target="_blank">zde</a>.
                                    </p>
                                    <p>
                                        Pokud by se nakonec Váš tým nemohl zúčastnit, dejte nám prosím vědět.
                                    </p>
                                    <div className='payment_section__qr'>
                                        <WebsystemLoginQRCode
                                            idTeam={user.Id }
                                            setError={setError }
                                        />
                                    </div>
                                </>
                                :
                                <div className='payment_section'>
                                    <ul>
                                        <li>Číslo účtu: <strong>2602911855/2010</strong></li>
                                        <li>Částka: <strong>300 Kč</strong></li>
                                        <li>Variabilní symbol: <strong>2401{user.Id.toString().padStart(4, '0')}</strong></li>
                                        <li>Zpráva pro příjemce: Zkratka2024 startovné - {user.Name}</li>
                                    </ul>
                                    <div className='payment_section__qr'>
                                        <PaymentQRCode
                                            accountNumber='CZ2220100000002602911855'
                                            amount='300'
                                            variableSymbol={'2401' + user.Id.toString().padStart(4, '0')}
                                            message={'Zkratka2024 startovné -' + user.Name}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    </Paragraph>
                    : <></>
                }
                {!state?.token
                    ?
                    <Paragraph>
                        Pro přihlášení týmu na 7. ročník Zkratky prosím vyplňte následující formulář. Po úspěšné registraci na této stránce najdete instrukce pro zaplacení startovného a následně polohu startu.
                    </Paragraph>
                    : <></>
                }

                <Spacer />

                <TextInput
                    label='Jméno týmu'
                    value={user.Name}
                    setValue={e => setName(e.target.value)}
                    type={ETextInputType.INPUT}
                    errorMessage={nameError}
                    required={true }
                />
                <TextInput
                    label='Heslo'
                    value={password}
                    setValue={e => setPassword(e.target.value)}
                    type={ETextInputType.PASSWORD}
                    errorMessage={passwordError}
                    required={true}
                />
                <TextInput
                    label='Kontaktní telefon'
                    value={user.Phone}
                    setValue={e => setPhone(e.target.value)}
                    type={ETextInputType.INPUT}
                    errorMessage={phoneError}
                    required={true}
                />

                <Spacer />

                <Headline text='Seznam hráčů' size={3} />

                {user.Players?.map((p, i) => (
                    <Player key={p.Id} player={p} ord={i + 1} showErrors={showErrors} setUser={setUser} user={user} />
                ))}

                {
                    (user.Players && user.Players.length < 5)
                        ? <Button text='Přidat hráče' onClick={addPlayer} secondary={true} icon={<MdAddCircleOutline size={25 } /> }/>
                        : ''
                }

                <Spacer />
                {newRegistration
                    ? <Paragraph>
                        <div className = 'disclaimer'>
                            <p>S registrací dáváte souhlas s uchováním osobních údajů pro účely hry.
                                Zadané telefony budou po hře trvale smazány, stejně jako to bylo provedeno u minulých ročníků.
                                Mail může být použit pro pozvání na další ročník Zkratky nebo na jinou námi organizovanou hru.
                                Pokud nebudete chtít dostávat tyto pozvánky, mail na vyžádání smažeme.
                            </p>
                            <p>Fotky pořízené na hře slouží k reportážním účelům a budou vystaveny zde nebo na veřejných úložištích.
                                Pokud nebudete souhlasit se zveřejněním fotografie, na které jste zachyceni, na požádání danou fotku odstraníme.
                            </p>
                        </div>
                    </Paragraph> 
                    : ''
                }

                <Button text={user.Id < 0 ? 'Registrovat' : 'Uložit'} onClick={registerButtonClick }/>
        </div>
        </>
    )
}