import { AppContext } from "../context"
import { useTypedSelector } from "../hooks"

export interface IAppContextproviderProps {
    children? : React.ReactNode
}

export const AppContextProvider: React.FC<IAppContextproviderProps> = (props) => {
    const { children } = props
    const appState = useTypedSelector((state) => state.app)

    return (
        <AppContext.Provider value={{ state: appState }}>
            {children}
        </AppContext.Provider >
    )
}