import { get, post } from "./axiosClient";

type LoginUserProps = {
    login: string,
    password: string
}

type LoginUserResponse = {
    token?: string,
    errorMessage?: string
}

export const getId = async ({ login }: LoginUserProps): Promise<string> => {
    return await get({
        params: {
            name: login
        },
        path: 'login'
    }).then((res) => {
        if (res.status === 200) {
            return  res.data 
        } else {
            return  '' 
        }
    });
}


export const loginUser = async ({ login, password }: LoginUserProps): Promise<LoginUserResponse> => {
    return await post({
        data: {},
        params: {
            name: login,
            password: password
        },
        path: 'login'
    }).then((res) => {
        if (res.status === 200) {
            return { token: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}

type GenerateNewLoginTokenProps = {
    id: number,
    token: string
}

export const generateNewLoginToken = async ({ id, token }: GenerateNewLoginTokenProps): Promise<LoginUserResponse> => {
    return await post({
        data: {},
        params: {
            idTeam: id
        },
        path: 'login/newCode',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { token: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}

type SendForgottenPasswordProps = {
    login: string,
    email: string
}

type SendForgottenPasswordResponse = {
    success?: boolean,
    errorMessage?: string
}

export const sendForgottenPassword = async ({ login, email }: SendForgottenPasswordProps): Promise<SendForgottenPasswordResponse> => {
    return await post({
        data: {},
        params: {
            name: login,
            email: email
        },
        path: 'login/password'
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else if (res.status === 401) {
            return {errorMessage: "Jméno týmu nebo email hráče nesedí."}
        } else {
            return { errorMessage: "Obnova hesla se nezdařila, kontaktujte prosím organizátory emialem." }
        }
    });
}