import { EGame, EUserType } from "../utils"

export type TCookie = {
    name: string,
    value: string
}

export type TUser = {
    Id: number,
    Name: string,
    Phone: string,
    Password: string,
    Type: EUserType,
    Players?: TPlayer[],
    Payed?: boolean,
}

export type TPlayer = {
    Name: string,
    Id: number,
    Email?: string
}

export type TForumMessage = {
    Message: string,
    Author: string,
    Date?: string,
    Headline?: string,
    Forum?: string
}

export type AdminPageProps = {
    setPage: any
}

export type HistoryPageProps = {
    setPage: any,
    year: EGame
}

export type TCypher = {
    Id: number,
    name: string,
    type?: ECypherType,
    helps?: THelp[],
    url?: string,
    message?: string,
    points?: number
}

export enum ECypherType {
    CYPHER = "C",
    FINISH = "F",
    START = "S",
    BONUS = "B"
}

export enum EHelpType {
    NORMAL = "N",
    INSTRUCTION = "I",
    ABSOLUTE = "A",
    MINI = "M"
}

export type THelp = {
    help?: string,
    cypher?: number,
    points?: number
    order?: number,
    type?: EHelpType,
    name?: string,
    teams?: number
}

export type TSelect<K extends string | number> = {
    key: K,
    label: string
}