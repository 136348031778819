import './header.scss'
import { TbUserCircle } from "react-icons/tb";
import { zkratkaLogo } from '../../resources';
import { LoginMenuItem, MenuItem } from './menu';
import { useAction, useAppState } from '../../hooks';
import { EPage, EUserType } from '../../utils';
import { EAppState } from '../../types';
import {  } from '../../store/action_creators';
import { useEffect, useState } from 'react';
import { getUser } from '../../api';
import { UserModal } from '../modal/user_modal';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
    const { setAppLoginModalAction, setAppSmallMenuAction } = useAction()
    const { state } = useAppState()
    const [teamName, setTeamName] = useState('')
    const [admin, setAdmin] = useState(false)
    const [userModalVisible, setUserModalVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (state?.token) {
            getUser({ token: state?.token! }).then(res => {
                if (res && res.user && res.user.Name.length > 0) {
                    var name = ''
                    if (res.user.Name.length > 8) {
                        name = res.user.Name.substring(0, 8);
                        name = name + '...'
                    } else {
                        name = res.user.Name
                    }
                    setTeamName(name)
                    setAdmin(res.user.Type == EUserType.ADMIN)
                }
            })
        } else {
            setTeamName('')
            setAdmin(false)
        }
    }, [state?.token])

    const loginClick = () => {
        if (state?.token) {
            setUserModalVisible(!userModalVisible)
        } else {
            setAppLoginModalAction(true)
        }
    }

    const onHamburgerMenuClick = () => {
        setAppSmallMenuAction(!state?.[EAppState.SET_SMALL_MENU])
    }

    return (
        <>
            <div className='header' >
                <div className='header__logo' onClick={() => navigate(EPage.HOME)}>
                    <img src={zkratkaLogo} alt='logo' />
                </div>
                <div className='header__big_menu'>
                    <MenuItem text='Domů' page={EPage.HOME }/>
                    <MenuItem text='Pravidla' page={EPage.INFO} />
                    <MenuItem text='Týmy' page={EPage.TEAMS} />
                    <MenuItem text='Po hře' page={EPage.HISTORY} />
                    <MenuItem text='Diskuze' page={EPage.FORUM} />
                    {admin ? <MenuItem text='Admin' page={EPage.ADMIN} /> : '' }
                    
                </div>
                <div className='header__login' onClick={loginClick}>
                    <div className='header__login__icon'>
                        <TbUserCircle size={ 32 }/>
                    </div>
                    <div className='header__login__text'>
                        {state?.token ? teamName : 'Přihlásit se'}
                    </div>
                </div>
                <div className={state?.[EAppState.SET_SMALL_MENU] ? 'header__hamburger open' : 'header__hamburger closed'} onClick={onHamburgerMenuClick }>
                    <span className='header__hamburger__first'></span>
                    <span className='header__hamburger__second'></span>
                    <span className='header__hamburger__third'></span>
                </div>

            </div>
            {
                state?.[EAppState.SET_SMALL_MENU]
                    ?
                        <div className='small_menu'>
                            <MenuItem text='Domů' page={EPage.HOME} />
                            <MenuItem text='Pravidla' page={EPage.INFO} />
                            <MenuItem text='Seznam týmů' page={EPage.TEAMS} />
                            <MenuItem text='Po hře' page={EPage.HISTORY} />
                            <MenuItem text='Diskuze' page={EPage.FORUM} />
                            {admin ? <MenuItem text='Admin' page={EPage.ADMIN} /> : ''}
                            {state?.token && !admin ? <MenuItem text='Stránka týmu' page={EPage.REGISTRATION} /> : ''}
                            <LoginMenuItem/>
                        </div>
                    :
                        <></>
            }

            <UserModal closeClick={() => setUserModalVisible(false)} visible={userModalVisible }/>
        </>
    )
}