import { ECypherType, EHelpType, TCypher } from "../../../types";

export const cyphers2024: Array<TCypher> =
[
    {
        Id: 0,
        "name": "Start",
        "type": ECypherType.START,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/00_START.pdf",
        "message": "",
        "points": 0,
        "helps": [
            {
                "cypher": 0,
                "order": 1,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "První šifra měla tvar deštníků (v rozměru 3x2), stačilo přečíst barvu letošních deštníků jako brailleovo písmo. <br/> Druhá šifra byla morseovka, pokud šlo o tečku, byly čára do oblouku, čárka byla rovně, velikost čárky/oblouku byla pořadí v písmeni.<br/>  Třetí šifra byl semafor - dva z osmi zubů byly vyznačené. Bylo potřeba si všimnout, že ke každému z kol patří trojciferné číslo, která označuje násobek 45, tedy úhel otočení - pak stačí vždy jednou pootočit, přečíst první, pootočit podruhé, přečíst druhé, atd. <br/>  Čtvrtá šifra je text, kde každé slovo začíná jedním z písmen ABDHP neboli mocninami dvojky - stačí přečíst po větách jako binárku. <br/> Na všechny zmíněné principy naváděly obrázky u orgů vydávajících řešení a nápovědy. ",
                "teams": 0
            }
        ]
    },
    {
        Id: 1,
        "name": "SACHOVNICE",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/01_SACHOVNICE.pdf",
        "message": "",
        "points": 10,
        "helps": [
            {
                "cypher": 1,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Všimli jste si, že každý startovní princip lze zapsat pomocí černých a bílých polí?",
                "teams": 108
            },
            {
                "cypher": 1,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Je potřeba použít znovu základní princip, kterým byl označený org vydávající šifru a luštila se jím daná startovní šifra. Konkrétně na každém očíslovaném místě se nachází buď začátek morseovky (z černých dokud nenajdete tři bílé v řadě), levý horní roh Braillova písma, začátek binárky nebo střed semaforu. Číst je potom potřeba z černobílé tabulky v pořadí čísel.",
            },
            {
                "cypher": 1,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Pozůstatky železničního mostu",
                "teams": 13
            }            
        ]
    },
    {
        Id: 2,
        "name": "OBRAZKY",
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/02_OBRAZKY.pdf",
        "type": ECypherType.CYPHER,
        "message": "",
        "points": 10,
        "helps": [
            {
                "cypher": 2,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Tak tohle je nápověda na houby.",
                "teams": 45
            },
            {
                "cypher": 2,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Každý obrázek napovídá na název nějaké houby, který délkou odpovídá počtu slov věty popisující tento obrázek. Písmeno u obrázku je obsaženo v názvu houby a vybere Vám tak slovo z věty. Tato slova čtěte v pořadí obrázků.",
                "teams": 9
            },
            {
                "cypher": 2,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Lípa hasičů",
                "teams": 0
            }
            
        ]
    },
    {
        Id: 3,
        "name": "TABULKA",
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/03_TABULKA.pdf",
        "type": ECypherType.CYPHER,
        "message": "",
        "points": 10,
        "helps": [
            {
                "cypher": 3,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Kolik políček je začerněných.",
                "teams": 106
            },
            {
                "cypher": 3,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Každé číslo říká, kolik políček je začerněných směrem do pravého dolního rohu (včetně políčka samotného). Je potřeba tuto úlohu vyřešit a poté přečíst výsledných pět znaků a najít takové místo na mapy.cz. (poslední tři znaky jsou čísla)",
                "teams": 76
            },
            {
                "cypher": 3,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "AA020",
                "teams": 6
            }
            
        ]
    },
    {
        Id: 4,
        "name": "SIPKY",
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/04_SIPKY.pdf",
        "type": ECypherType.CYPHER,
        "message": "",
        "points": 10,
        "helps": [            
            {
                "cypher": 4,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Vybarvi čtvercovou síť.",
                "teams": 14
            },
            {
                "cypher": 4,
                "order": 2,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Upřesnítko vám poradí slepý.",
                "teams": 11
            },
            {
                "cypher": 4,
                "order": 3,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Pokud si u každé šipky nakreslíte, kam míří, vyjde vám síť, kterou lze poté vybarvit dle návodu v šipkách. Počet segmentů v šipce totiž odpovídá počtu segmentů kolem čáry, kterou jste kreslili od dané šipky. Řešením je potom grafický obrázek, který lze najít na mapách a pod ním je upřesnění v brailleově písmu, abyste šli k tomu správnému.",
                "teams": 6
            },            
            {
                "cypher": 4,
                "order": 4,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Hájkův Altán",
                "teams": 1
            }
        ]
    },
    {
        Id: 5,
        "name": "PODTRZITKA",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/05_PODTRZITKA.pdf",
        "message": "",
        "points": 10,
        "helps": [
            {
                "cypher": 5,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "1._psilon jsme v tajence nepoužili, 2._psilon neexistuje.",
                "teams": 60
            },
            {
                "cypher": 5,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Zadání lze rozdělit na úseky, kde každý začná číslicí a mají podobný tvar. Každý takový úsek vám říká, kolikáté písmeno, jehož hláskování má daný tvar, máte číst. Například á=1._ bé=1._é cé=2._é atd. Nezapomeňte na upřesnítko.",
                "teams": 25
            },
            {
                "cypher": 5,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Naučná stezka Historií Horních Měcholup",
                "teams": 0
            }
        ]
    },
    {
        Id: 6,
        "name": "TVARY",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/06_TVARY.pdf",
        "message": "",
        "points": 22,
        "helps": [
            {
                "cypher": 6,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Přilepte stejná písmena na sebe.",
                "teams": 25
            },
            {
                "cypher": 6,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Je potřeba dopsat do zadání hesla z minišifer. Potom je dobré začít rozpůleným logem, které naznačuje skládání přes sebe a také si můžete všimnout, že na obou jeho čtvercích je stejné písmeno. Pokud takto přiložíte (a přilepíte) přes sebe všechna stejná písmena, vyjde vám prostorový útvar, ze kterého už by potom neměl být problém přečíst tajenku ve směru šipek a v pořadí jejich čísel.",
                "teams": 14
            },
            {
                "cypher": 6,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Hřiště u AA019",
                "teams": 11
            },
            {
                "cypher": 6,
                "order": 6,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "6-1",
                "help":"SVIDA - bylo potřeba vzít v dané kategorii prvního reprezentanta (tj. nej...) a přečíst první sloupeček.",
                "teams": 17
            },
            {
                "cypher": 6,
                "order": 7,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "6-2",
                "help": "SMENA - zadání navádí na trojpísmené palindromy, čteme středový, tedy druhý, znak.",
                "teams": 3
            },  
            {
                "cypher": 6,
                "order": 8,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "6-3",
                "help": "SLUHA - je potřeba si uvědomit, že se jedná o trojice, vzít třetího zástupce a přečíst třetí sloupeček.",
                "teams": 55
            }, 
            {
                "cypher": 6,
                "order": 9,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "6-4",
                "help": "POCIT - je potřeba si uvědomit, že se jedná o čtveřice, kde jeden chybí - stačí ho doplnit a přečíst čtvrtá písmena, na což naváděly tučné znaky, v pořadí obrázků reprezentující celou skupinu.",
                "teams": 6
            },
            {
                "cypher": 6,
                "order": 10,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "6-5",
                "help": "SKOBA - je potřeba proskákat šachovým jezdcem zadaná pole a to co nejkratší cestou bez opakování, taková je jednoznačná. Potom číst zleva shora, tj. v důsledku každé páté písmeno zadání.",
                "teams": 38
            },
            {
                "cypher": 6,
                "order": 11,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "6-6",
                "help": "PUGET - je potřeba pochopit z prvních pěti minišifer číselný princip a číst každé šesté.",
                "teams": 55
            }
        ]
    },
    {
        Id: 7,
        "name": "LIMITA",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/07_LIMITA.pdf",
        "message": "",
        "points": 10,
        "helps": [
            {
                "cypher": 7,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Na Náměstí Míru cesta nezačíná.",
                "teams": 24
            },
            {
                "cypher": 7,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Pokud si budete šifru analyzovat dlouho, můžete z čísel nad šipkami získat jednu mezitajenku a ze směrů šipek a dělení na úseky délky 1-4 druhou. Obě tyto mezitajenky vás navádí na to, že budete něčím jezdit. Písmena u šipek jsou totiž počátečními písmeny konečných stanic Pražského metra. Je tedy potřeba dopočítat na kterou stanici dojedu po ujetí daného počtu zastávek a z té dopočítat psímeno názvu.",
                "teams": 12
            },
            {
                "cypher": 7,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Prolézačky u nádrže R3",
                "teams": 6
            }
        ]
    },
    {
        Id: 8,
        "name": "TICTAC",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/08_TICTAC.pdf",
        "message": "Spolu se šifrou byste měli dostat krabičku bonbonů TicTac.",
        "points": 10,
        "helps": [            
            {
                "cypher": 8,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Tic-Tac, Tic-Tac, Tic-Tac, ...",
                "teams": 30
            },
            {
                "cypher": 8,
                "order": 2,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Semafor",
                "teams": 11
            },
            {
                "cypher": 8,
                "order": 3,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "TLDR: Čtvrt, půl, třičtvrtě, celá, kolik to ručičko kolik to dělá? Celý návod: V šifře můžete vidět digitální čísla sestavená z čtvrtinových, polovičních, tříčtvrtečních a celých bonbonů Tic-Tac. Je potřeba se zamyslet, co běžně pracuje s těmito zlomky a zároveň na to navádí Tic-Tac. Pokud si název párkrát zopakujete, zjistíte, že napodobujete zvuk hodin, je tedy potřeba si pro každé číslo nakreslit do ciferníku hodin polohu ručiček a přečíst ji jako semafor. Pro úplnost příklad - číslovka 2 složená z polovičních bonbónů značí půl druhé, tedy jde o písmeno E v semaforu.",
                "teams": 4
            },
            {
                "cypher": 8,
                "order": 4,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Psí hřiště asi 800m směrem JJV",
                "teams": 0
            }
        ]
    },
    {
        Id: 9,
        "name": "OSMISMERKA",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/09_OSMISMERKA.pdf",
        "message": "",
        "points": 10,
        "helps": [
            {
                "cypher": 9,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Každému slovu legendy patří dva směry.",
                "teams": 46
            },
            {
                "cypher": 9,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Pokud přečtete zakroužkovaná písmena, zjistíte, že se jedná o písmena tajenky osmisměrky. Jak se k tajence osmisměrky běžně dostanete? Jsou to ta písmena, která zbyla po vyškrtání slov legendy. Je tedy potřeba si postup zrekonstruovat - najít všechna slova, tak aby zbyla právě zakroužkovaná písmena. Tato slova lze potom doplnit do předepsaných políček pro legendu v obvyklém abecedním pořadí. Může se navíc hodit, že od každého písmene abecedy je právě jedno slovo. Pokud se potom podíváte na zvýrazněná podtržítka, zjistíte, že jde vždy o písmena DHLP, která označují směry. Pokud jsou na řádku dvě, nikdy se nevylučují, ale udávají šikmý směr. Máte tedy jeden z osmi směrů pro semafor, pro druhý směr je potřeba si vzpomenout, že jste ho už použili dříve, když jste vyškrtávali slovo z osmisměrky. Pak už jen přečtete tajenku v semaforové abecedě v pořadí legendy.",
                "teams": 27
            },
            {
                "cypher": 9,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Brouci ve stromech",
                "teams": 5
            }            
        ]
    },
    {
        Id: 10,
        "name": "HOUSENKY",
        "type": ECypherType.CYPHER,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/10_HOUSENKY.pdf",
        "message": "",
        "points": 22,
        "helps": [
            {
                "cypher": 10,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Už jste byli na návštěvě u Skřítků pro radost?",
                "teams": 33
            },
            {
                "cypher": 10,
                "order": 2,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Čti imaginární potok.",
                "teams": 34
            },
            {
                "cypher": 10,
                "order": 3,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Do 'housenek' je potřeba vyplnit hesla z minišifer pouze do tělíček směrem od hlavy (loga), nalezených u soch skřítků. Jakmile to máte, stačí jen přečíst tajenku v potoce (po proudu), na což navádělo několik různých hintů v minišifrách. Pokud máte správné tajenky z minišifer a nevíte si rady s interpretací, použijte skřítky, u kterých jste minišifru našli.",
                "teams": 14
            },
            {
                "cypher": 10,
                "order": 4,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Modrá škola",
                "teams": 7
            },
            {
                "cypher": 10,
                "order": 6,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "APOLENKA",
                "help": "DEČKA - první písmena dávají text Koho mám při sobě. Správná odpověď je kocoura (který se jmenuje Tygřík a jeho příběh si můžete přečíst po naskenování QR kódu), takže vezmu odpovídající heslo z tabulky.",
                "teams": 20
            },
            {
                "cypher": 10,
                "order": 7,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "JENDA",
                "help": "HRNEK - na vynechaných místech chybí pí, za ním následuje vždy čárka a poté ihned bez mezery jedno slovo. Je potřeba číst za zmíněnou čárkou tolikáté písmeno, jako je cifra v desetinném rozvoji pí. Tj. v první větě první, v druhé čtvrté, ve třetí opět první, atd. Vyjde tajenka Co mám u hlavy - tam byl hrnek.",
                "teams": 23
            },
            {
                "cypher": 10,
                "order": 8,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "RADEK",
                "help": "HOUSLE - jak naznačuje houslový klíč (a skřítek hudebník), jedná se o noty. Je potřeba si je přepsat do notového zápisu, čímž nám vznikne nápis v brailleově písmu. (Půlcentimetrové noty jsou půlové, tedy bílé, čtvrtcentimetrové čtvrťové, tedy černé, sekvence jsou vždy po dvou trojicích, což dá komplet šestici pro znak. Tajenka zní Na jaký nástroj hraju - odpověď jsou housle.",
                "teams": 30
            },
            {
                "cypher": 10,
                "order": 9,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "JONÁŠ",
                "help": "PTÁČEK - kachny jsou jen námořní vlajky do poloviny potopené v potoce (jeden z mnoha hintů na semílačku), stačí přečíst a odpovědět na otázku, Co mi sedí na ruce? - odpovědí je ptáček.",
                "teams": 14
            }, 
            {
                "cypher": 10,
                "order": 10,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "MARJÁNKA",
                "help": "JASNO - Máme tři typy překážek, nejnižší je tečka, střední čárka, nejvyšší lomítko a přečteme morseovku. Tajenka vás odkazuje na opakování šifry Apolenky, tedy se znovu ptám koho mám při sobě, ale odpověď je zde kůň (pojemnovaný Bohoušek), proto beru odpovídající heslo JASNO.",
                "teams": 30
            },           
            {
                "cypher": 10,
                "order": 11,
                "points": 0,
                "type": EHelpType.MINI,
                "name": "RYK",
                "help": "MYŠKA - Myška běží za sýrem a poté k východu z bludiště a čte si cestou mezitajenku. Ta vám řekne, že kdyby se myška necpala, tak mohla běžet zkratkou a ještě zkákat o dva. Najdu tedy kratší cestu k východu (kde nepotkám sýr) a čtu každý druhý znak. Pokud začnu rovnou o dva, vyjde mi tajenka Jaké zvíře sedí na rameni - a Rykovi sedí na rameni právě ta myška.",
                "teams": 26
            }
        ]
    },
    {
        Id: 11,
        "name": "HESLO",
        "url": "https://www.sifrovacihra.cz/zkratka/2024/sifry/11_HESLO.pdf",
        "type": ECypherType.CYPHER,
        "message": "Cílové heslo zadávejte stejně, jako kdyby to byl kód dalšího stanoviště. Po jeho zadání můžete stále zadávat bonusové heslo.",
        "points": 10,
        "helps": [            
            {
                "cypher": 11,
                "order": 1,
                "points": 0,
                "type": EHelpType.NORMAL,
                "help": "Popište si zbylé stěny 26-stěnu.",
                "teams": 31
            },
            {
                "cypher": 11,
                "order": 2,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "V trojúhelničcích vidíte zbývajících osm písmen, která se nepoužila na šifře šest. Útvar tam složený je totiž 26-stěn a na každé jeho pole lze tak napsat jedno psímenko abecedy. Začátek této šifry vám dává návod, jak zbylá písmenka doplnit na již existující útvar a potom už stačí jen přečíst písmena na místech otazníků.",
                "teams": 9
            },
            {
                "cypher": 11,
                "order": 3,
                "points": 0,
                "type": EHelpType.ABSOLUTE,
                "help": "Gumička",
                "teams": 8
            }
        ]
    },
    {
        Id: 12,
        "name": "Bonus",
        "type": ECypherType.BONUS,
        "message": "",
        "points": 2,
        "url": "https://www.sifrovacihra.cz/zkratka/2024/Pruvodce.pdf",
        "helps":[
            {
                "cypher": 12,
                "order": 1,
                "points": 0,
                "type": EHelpType.INSTRUCTION,
                "help": "Bylo potřeba si uvědomit, že jde o dny okolo Zkratky a říct si, kdy to je. Konkrétně Zkratka je DNES, afterparty POZÍTŘÍ, atd.",
                "teams": 0
            }
        ]
    }
]