import { MoonLoader } from "react-spinners";
import './loading.scss'

type LoadingProps = {
    size: number
}

export const Loading = ({size }: LoadingProps) => {
    return (
        <div className='loading'>
            <div className='loading__spinner'>
                <MoonLoader
                    color='#cf8746'
                    loading={true}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={size }
                />
            </div>
        </div>
    )
}