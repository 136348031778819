import { useEffect, useState } from 'react';
import {  generateNewLoginToken, getAdminTeams } from '../../api';
import { AdminMenu, ErrorMessage, Headline, Paragraph, SuccessMessage, TextInput } from '../../components';
import { useAppState } from '../../hooks';
import { AdminPageProps, TUser } from '../../types';
import { EAdminPage, ETextInputType, EUserType } from '../../utils';
import { LoadingPage } from '../loading/LoadingPage';
import '../page.scss';


export const Passwords = ({ setPage }: AdminPageProps) => {
    const [loading, setLoading] = useState(true)
    const [teams, setTeams] = useState<TUser[]>()
    const [teamName, setTeamName] = useState("")
    const { state } = useAppState()
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')


    useEffect(() => {
        loadTeams()
    }, [])

    const loadTeams = () => {
        getAdminTeams({ token: state?.token! }).then(res => {
            if (res.teams) {
                setTeams(res.teams.filter(t=>t.Type === EUserType.TEAM))
            }
            setLoading(false)
        })
    }

    const setName = (input: string) => {
        setTeamName(input)
        setError('')
    }

    const generateToken = (id: number, name: string) => {
        setError('')
        setSuccess('')
        window.scrollTo({ top: 0, behavior: 'smooth' });
        generateNewLoginToken({ id: id, token: state!!.token!!}).then((response) => {
            if (response.token) {
                setSuccess(`Přihlášení pro tým ${name}: https://zkratka.sifrovacihra.cz/?loginToken=${response.token}`)
            } else {
                setError(response.errorMessage || 'Vygenerování přihlašovacího odkazu se nezdařilo :(')
            }
        }).catch(() => {
            setError('Vygenerování přihlašovacího odkazu se nezdařilo :(')
        })
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    }

    return (

        <div className='page'>
            <AdminMenu setPage={setPage} selectedPage={EAdminPage.PASSWORD} />
            <ErrorMessage text={error} />
            <SuccessMessage text={success} />

            <Headline text='Obnovit heslo' size={1} />

            <p>Kliknutím na zvolený tým se vygeneruje odkaz, který týmu dovolí se přihlásit na web.
                Po přihlášení by si měl změnit heslo, platnost odkazu není garantovaná.</p>
            

            <Paragraph>
                <>
                    <TextInput
                        label='Vyhledat tým'
                        setValue={e => setName(e.target.value)}
                        errorMessage={""}
                        type={ETextInputType.INPUT}
                        value={teamName}
                    />

                </>
            </Paragraph>

            {
                teams?.filter(t => t.Name.toLowerCase().includes(teamName.toLowerCase())).map(t =>
                    <div onClick={() => generateToken(t.Id, t.Name)} className='passwords__team_name'> {t.Name} </div>
                )
            }


        </div>
    )
}