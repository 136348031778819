import { MdErrorOutline } from "react-icons/md";
import { EMessageType } from "../../utils";
import { Message } from "./Message";

type ErrorMessageProps = {
    text: string
}

export const ErrorMessage = ({ text }: ErrorMessageProps) => {
    return (
        <Message text={text} icon={<MdErrorOutline size={25 }/>} type={EMessageType.ERROR}/>
        )
}