export enum EAppAction {
    SET_LOGIN_MODAL = 'APP.SET_LOGIN_MODAL',
    SET_TOKEN = 'APP.SET_TOKEN',
    SET_SMALL_MENU = 'APP.SET_SMALL_MENU',
}

interface setAppLoginModalAction {
    type: EAppAction.SET_LOGIN_MODAL,
    payload?: boolean
}

interface setAppTokenAction {
    type: EAppAction.SET_TOKEN,
    payload?: string
}

interface setAppSmallMenuAction {
    type: EAppAction.SET_SMALL_MENU,
    payload?: boolean
}


export type TAppAction = setAppLoginModalAction | setAppTokenAction |  setAppSmallMenuAction