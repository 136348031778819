import { useEffect, useState } from "react"
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { getForumMessages } from "../../api"
import { TForumMessage } from "../../types"
import { EGame, EPage } from "../../utils"
import { Loading } from "../loading"
import { Headline, Paragraph, Spacer } from "../page"

export const NewMessages = () => {
    const [messages, setMessages] = useState<TForumMessage[]>([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        reloadMessages()
    }, [])

    const reloadMessages = () => {
        loadMessages()
    }

    const loadMessages = () => {
        setLoading(true)
        getForumMessages({
            forum: EGame.ZKR2024
        }).then(res => {
            if (res.messages) {
                setMessages(res.messages.slice(0, 3))
            }
            setLoading(false)
        })
    }

    return (
        <div className='org_messages' onClick={() => loadMessages()}>
            <Headline text='Nejnovější zprávy' size={3} />
            {loading ? <Loading size={40} /> :
                <div className='org_messages__messages'>
                    {
                        messages.map(m =>
                            <Paragraph>
                                <div className='org_messages__messages__message'>
                                    <div className='org_messages__messages__message__headline'>
                                        <strong>{m.Author}</strong>
                                    </div>
                                    <div className='org_messages__messages__message__message'>
                                        {m.Message}
                                    </div>
                                </div>
                            </Paragraph>
                        )
                    }
                </div>
            }
            <Spacer/>
            <Paragraph>
                <>
                    <span className='href' onClick={() => navigate(EPage.FORUM)}> Další zprávy <MdOutlineKeyboardDoubleArrowRight size={20} className='icon' /></span>
                </>
            </Paragraph>
        </div>
    )
}