import QRCode from "qrcode.react"
import './qrcode.scss'

type PaymentQRCodeProps = {
    accountNumber: string,
    amount: string,
    variableSymbol: string,
    message: string
}

export const PaymentQRCode = ({ accountNumber, amount, variableSymbol, message }: PaymentQRCodeProps) => {
    const formattedAmount = parseFloat(amount).toFixed(2)
    const qrValue = `SPD*1.0*ACC:${accountNumber}*AM:${formattedAmount}*CC:CZK*MSG:${message.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}*X-VS:${variableSymbol}*RN:Šifrovací Hra, z.s.`;
    

    return (
        <div className = 'qrCode' >
            <QRCode value={qrValue} size={128} fgColor='#333333'/>
        </div>
    )
}