import { get, post } from "./axiosClient"

type GetStarsProps = {
    cypherId: number,
    token?: string
}

type GetStarsResponse = {
    stars?: number,
    teamStars?: number,
    votes?: number,
    errorMessage?: string
}

export const getStars = async ({ cypherId, token }: GetStarsProps): Promise<GetStarsResponse> => {
    return await get({
        params: { cypherId: cypherId },
        path: 'websystem/cypher/stars',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return {
                stars: res.data.stars,
                teamStars: res.data.teamStars,
                votes: res.data.votes
            }
        } else {
            return { errorMessage: res.data }
        }
    });
}

type SetStarsProps = {
    cypherId: number,
    token: string,
    stars: number
}

export const setStars = async ({ cypherId, token, stars }: SetStarsProps): Promise<GetStarsResponse> => {
    return await post({
        data: {},
        params: {
            cypherId: cypherId,
            stars: stars
        },
        path: 'websystem/cypher/stars',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { stars: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}