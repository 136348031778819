import { ChangeEvent } from "react"
import { TSelect } from "../../types"
import './page.scss'

type SelectProps<K extends string | number> = {
    selectedValue: TSelect<K> | undefined,
    setSelectedValue: (value: TSelect<K> | undefined) => void,
    values: TSelect<K>[],
    label: string
}


export const Select = <K extends string | number>({ selectedValue, setSelectedValue, values, label }: SelectProps<K>) => {

    const onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(values.find(v => v.key == e.target.value))
    }

    return (
        <div className='select'>
            <div className='select__label'>{label}</div>
            <select
                className='select__select'
                onChange={(e) => onSelect(e)}
                value={selectedValue?.key}>
                {
                    values.map(v =>
                        <option value={v.key.toString()}> {v.label}</option>)
                }
            </select>
        </div>
        
        )
}