import { useEffect, useState } from "react";
import { IoMdDownload } from 'react-icons/io';
import { getAdminTeams } from "../../api";
import { AdminMenu, CheckBox, Headline, Loading } from "../../components";
import { useAppState } from "../../hooks";
import { AdminPageProps, TUser } from "../../types";
import { EAdminPage, EUserType } from "../../utils";
import './admin.scss'
import { TbMoneybag } from "react-icons/tb";

export const Contacts = ({ setPage }: AdminPageProps) => {
    const [loading, setLoading] = useState(true)
    const { state } = useAppState()
    const [teams, setTeams] = useState<TUser[]>()
    const [nonPayed, setNonPayed] = useState(true)

    useEffect(() => {
        loadTeams()
    },[])

    const loadTeams = () => {
        getAdminTeams({ token: state?.token! }).then(res => {
            if (res.teams) {
                setTeams(res.teams.filter(t => t.Type === EUserType.TEAM ))
            }
            setLoading(false)
        })
    }

    const downloadTeamListCsv = () => {
        if (teams) {
            let list = Array<string>()
            teams.filter(t => t.Payed).
                forEach(t => {
                    let row = t.Name + ";"
                    row = row + t.Phone + ";"
                    t.Players?.forEach(p => row = row + p.Name + ";")
                    list.push(row)
                })

            const data = list.join("\n");
            let csvContent = `data:text/csv;charset=utf-8,${data}`;
            csvContent = csvContent.replace('#', '')
            const encodedURI = encodeURI(csvContent);

            var link = document.createElement("a")
            link.href = encodedURI
            link.download = `Prezencka_Zkratka2024.csv`;
            link.click();
        }
    }

    return (
        <div className='page'>
            <AdminMenu setPage={setPage} selectedPage={EAdminPage.CONTACTS} />
            <Headline text='Kontakty' size={1} />

            <Headline text='Prezenčka' size={3} />

            <div className='admin_team_list_csv' onClick={() => downloadTeamListCsv()}><IoMdDownload size={20 }/> Stáhnout prezenčku</div>

            <Headline text='Emaily' size={3} />

            <CheckBox label='Pouze nezaplacené' checked={nonPayed} setChecked={setNonPayed }/>

            {loading ? <Loading size={60} /> :
                <div className='team_emails'>
                    {
                        teams?.filter(t => !t.Payed || !nonPayed ).map(t => t.Players?.filter(p => p.Email && p.Email != '').map(p => <div className='team_emails__email'>{p.Email},</div>))
                    }
                </div>
            }

            <Headline text='Kontakty' size={3} />
            {loading ? <Loading size={60} /> :
                <div className='teams_contacts'>
                    {
                        teams?.map(t =>
                            <div className='teams_contacts__team'>
                                <div className='teams_contacts__team__name'>
                                    {t.Payed ?
                                    <div className='teams_list__team__name__badge'>
                                        <TbMoneybag />
                                    </div>
                                        : ''}{t.Name}
                                </div>
                                <div className='teams_contacts__teams__phone'>{t.Phone}</div>
                                <div className='teams_contacts__team__emails'>{t.Players?.filter(p => p.Email && p.Email != '').map(p => <div className='teams_contacts__team__emails__email'>{p.Email},</div>)}</div>
                            </div>
                            )
                    }                    
                </div>
            }
        </div>
    )
}