import { useEffect, useState } from "react"
import { EHelpType, THelp } from "../../types"

type HelpProps = {
    help: THelp
}

export const Help = ({ help }: HelpProps) => {

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(false)
    }, [help])

    function helpInfo(): string {
        switch (help.type) {
            case EHelpType.ABSOLUTE:
                return "řešení šifry"
            case EHelpType.INSTRUCTION:
                return "postup řešení"
            case EHelpType.NORMAL:
                return `${help.order}. nápovědu`
            case EHelpType.MINI:
                return `řešení mini šifřičky ${help.name}`
        }
        return "Nic"
    }

    if (help.help == "") {
        return (<></>)
    }

    return (
        <div className="help">
            {visible ? <div className="visible" dangerouslySetInnerHTML={{ __html: help.help! }} /> : <div className="hidden" onClick={() => setVisible(true)}><>Zobrazit {helpInfo()}</></div>}
        </div>
    )
}