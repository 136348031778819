import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { GiPositionMarker } from "react-icons/gi";
import { GoCalendar } from "react-icons/go";
import { AiOutlineTeam } from "react-icons/ai";
import { FaBabyCarriage } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Headline, NewMessages, NewTeams, OrgMessages, Paragraph, Spacer } from '../../components';
import { EPage } from "../../utils";
import '../page.scss';
import './home.scss';

export const Home = () => {
    const navigate = useNavigate()

    return (
        <div className='page_and_news'>
            <div className='page'>
                <Headline text='Vítejte na webu šifrovací hry Zkratka!' size={1} />
                <Paragraph>
                    <p>
                        Jsme Pražská denní <a href="https://sifrovacky.cz/o-sifrovackach" className='href' target="_blank">šifrovací hra</a> určená <strong>pro začátečníky až středně pokročilé týmy</strong>.
                    </p>
                </Paragraph>

                <div className='info'>
                    <div className='info__box'>
                        <GoCalendar className='info__box__icon' size={60} />
                        <span className='info__box__text'>12. října 2024</span>
                    </div>
                    <div className='info__box'>
                        <GiPositionMarker className='info__box__icon' size={60} />
                        <span className='info__box__text'>Praha</span>
                    </div>
                    <div className='info__box'>
                        <AiOutlineTeam className='info__box__icon' size={60} />
                        <span className='info__box__text'>1-5 členné týmy</span>
                    </div>
                    <div className='info__box'>
                        <FaBabyCarriage className='info__box__icon' size={60} />
                        <span className='info__box__text'>Sjízdné s kočárkem</span>
                    </div>
                </div>

                <Headline text='Co od hry můžete čekat?' size={3} />
                <Paragraph>
                    <ul>
                        <li>Snažíme se zařadit spíše jednodušší šifry a máme systém, který vám umožní vzít nápovědy, kdy sami uznáte za vhodné (viz <span className='href' onClick={() => navigate(EPage.INFO)}>pravidla</span>). </li>
                        <li>Nicméně, obvykle u nás najdete i nějaké těžší/pracné/vícekrokové šifry, jejich počet i obtížnost se každý rok může lišit a obtížnost nemívá vzrůstající tendenci,
                            ale spíše se lehčí nějak střídají s těžšími.</li>
                        <li>Pokud jste začátečnický tým, počítejte s tím, že pravděpodobně nezvládnete hrou projít bez nápověd,
                            a pokud vás od pokračování odradí pár těžších šifer, možná moc daleko nedojdete. I tak je ale hra určena také pro vás a věříme, že si ji užijete.</li>
                        <li>Naopak, pokud sestavíte velmi pokročilý tým, nejspíše hrou prolétnete a moc si to neužijete. Bránit v účasti nikomu nebudeme,
                            ale prosíme, zvažte, zda nechcete takový tým raději rozdělit a třeba nabrat rodinu nebo nějaké kamarády začátečníky.</li>
                        <li>S odhadem obtížnosti a toho, co čekat, může pomoci <a href="https://www.sifrovacihra.cz" className='href' target="_blank">pohled na minulé ročníky</a>.</li>
                    </ul>
                </Paragraph>

                <Headline text='Kdo by neměl chybět v týmu?' size={3} />
                <Paragraph>
                    <ul>
                        <li>Někdo s dobrým logickým uvažováním</li>
                        <li>Někdo se schopností navigovat tým podle mapy.cz</li>
                        <li>Někdo, kdo má mobil s funkčním internetem a zvládne práci s webovým systémem</li>
                        <li>Někdo se širokým spektrem vědomostí (případně alespoň se znalostí, kde vědomosti nalézt)</li>
                        <li>Někdo, kdo zvládne včas přihlásit tým a zaplatit startovné</li>
                        <li>Občas se hodí i další schopnosti, například všimnout si druhé strany šifry nebo nebát se šifru stříhat, lepit, spálit, polít, sešívat a podobně... a nebo naopak zabránit zbytku týmu, aby těmito způsoby zničil každou šifru.</li>
                    </ul>
                </Paragraph>

                <div className='bigLink' onClick={() => navigate(EPage.REGISTRATION)}>
                    <MdOutlineKeyboardDoubleArrowRight size={25} className='icon' /> Registrovat tým na hru <MdOutlineKeyboardDoubleArrowLeft size={25} className='icon' />
                </div>

                <div className='news_in_page'>
                    <OrgMessages/>
                </div>

                <Headline text='Kdo hru organizuje?' size={3} />
                <Paragraph>
                    <>
                        <p>
                            Hru pro vás tvoří Ondra, Petr, Martin a Meggy pod záštitou Šifrovací hra, z.s.
                        </p>
                        <p>
                            Máte-li jakékoli dotazy nebo třeba chcete hru testovat, ozvěte se nám na mail <strong>zkratka.sifrovacihra@gmail.com</strong>.
                        </p>
                    </>
                </Paragraph>


                <Spacer />

                <Paragraph>
                    <p>
                        Jinak pokračujte ve zkoumání webu a těšíme se na vás na hře. A nezapomeňte: <strong>Zkratka nemusí být krátká a už vůbec ne pohodlná...</strong>
                    </p>
                </Paragraph>
            </div>
            <div className='news_boxes'>
                <div className='news_boxes__box'>
                    <OrgMessages/>
                </div>
                <div className='news_boxes__box'>
                    <NewTeams />
                </div>
                <div className='news_boxes__box'>
                    <NewMessages />
                </div>
            </div>
        </div>
     )
}