import QRCode from "qrcode.react"
import { useState } from "react"
import { generateNewLoginToken } from "../../api"
import { useAppState } from "../../hooks"
import { TUser } from "../../types"
import { Headline, Paragraph } from "../page"
import './qrcode.scss'

type WebsystemLoginQRCodeProps = {
    idTeam: number,
    setError: any
}

export const WebsystemLoginQRCode = ({ idTeam, setError }: WebsystemLoginQRCodeProps) => {
    const [loginToken, setLoginToken] = useState<string | undefined>()
    const { state } = useAppState()

    generateNewLoginToken({ id: idTeam, token: state!!.token!! }).then((response) => {
        if (response.token) {
            setLoginToken(response.token)
        } else {
            setError('Vygenerování přihlašovacího odkazu se nezdařilo :(')
        }
    }).catch(() => {
        setError('Vygenerování přihlašovacího odkazu se nezdařilo :(')
    })
    const qrValue = `https://www.sifrovacihra.cz/websystem/index.html?loginToken=${loginToken}`;

    if (!loginToken) {
        return <></>
    }

    return (
        <>
            <Headline text='Přihlášení do websystému' size={3} />
            <Paragraph>
                Pro přihlášení se do websystému můžete použít následující QR kód. Doporučujeme si jej vytisknout s sebou na hru, odpadne vám nutnost se přihlašovat jménem a heslem.
            </Paragraph>
        <div className='qrCode' >
                <QRCode value={qrValue} size={256} fgColor='#333333' />
                <div><a href={qrValue} target="_blank">{qrValue }</a></div>
            </div>
        </>
    )
}