import { TForumMessage } from "../../types"
import './forum.scss'

type ForumMessageProps = {
    message: TForumMessage
}

export const ForumMessage = ({ message }: ForumMessageProps) => {
    return (
        <div className='forum_message'>
            <div className='forum_message__headline'>
                <div className='forum_message__headline__name'>
                    {message.Headline ? message.Headline : message.Author } 
                </div>
                {
                    message.Headline
                        ?
                        <div className='forum_message__headline__author'>
                            {message.Author}
                        </div>
                        :
                            ''
                }
                
                
                <div className='forum_message__headline__date'>
                    {message.Date}
                </div>
            </div>
            <div className='forum_message__message'>
                <p style={{ whiteSpace: 'pre-wrap' }}>{message.Message}</p>                
            </div>
        </div>
        )
}