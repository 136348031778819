import { useEffect, useState } from "react"
import { getForumMessages } from "../../api"
import { AdminMenu, ForumForm, ForumMessage, Headline, Loading } from "../../components"
import { AdminPageProps, TForumMessage } from "../../types"
import { EAdminPage, EForumEndpoint, FORUM_ORG_NEWS } from "../../utils"


export const News = ({ setPage }: AdminPageProps) => {
    const [messages, setMessages] = useState<TForumMessage[]>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        reloadMessages()
    }, [])

    const reloadMessages = () => {
        getForumMessages({
            forum: FORUM_ORG_NEWS
        }).then(res => {
            if (!res.errorMessage) {
                setMessages(res.messages?.slice(0, 3))
            }
            setLoading(false)
        })
    }
    return (
        <div className='page'>
            <AdminMenu setPage={setPage} selectedPage={EAdminPage.NEWS} />
            <Headline text='Novinky' size={1} />
            <ForumForm forum={FORUM_ORG_NEWS} reloadMessage={reloadMessages} inputLabel={'Nadpis'} endpoint={EForumEndpoint.ADMIN_FORUM }/>

            {loading ? <Loading size={60} /> :
                <div className='forum_messages'>
                    {
                        messages?.map(m => <ForumMessage message={m} />)
                    }
                </div>
            }
        </div>
        )
}
