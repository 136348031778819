import { ChangeEvent } from 'react';
import './form.scss';

type TextInputProps = {
    label: string,
    required?: boolean,
    type: string,
    errorMessage: string,
    value: string,
    setValue: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const TextInput = ({ label, required, type, errorMessage, value, setValue }: TextInputProps) => {
    return (
        <div className='text_input'>
            <div className='text_input__label'>
                {label}{required ? <span className='text_input__label__required'>*</span> : ''}
            </div>
            <div className='text_input__input'>
                <div className={'text_input__input__text' + (errorMessage.length > 0 ? ' invalid' : '')}>
                    {{
                        'textarea':
                            <textarea
                                className='text_input__input__text__textarea'
                                onChange={setValue}
                                value={value}
                            ></textarea>
                    }[type] ||
                        <input
                            className='text_input__input__text__input'
                            type={type}
                            onChange={setValue}
                            value={value}

                        />
                    }
                </div>
                <div className='text_input__input__error'>
                    {errorMessage ? errorMessage: '' }
                </div>
            </div>
        </div>
    )
}