import { useState } from "react"
import { Button, TextInput } from ".."
import { saveForumMessage } from "../../api"
import { useAppState } from "../../hooks"
import { EForumEndpoint, ETextInputType } from "../../utils"

type ForumFormProps = {
    forum: string,
    reloadMessage: any,
    inputLabel?: string,
    endpoint: EForumEndpoint
}

export const ForumForm = ({ forum, reloadMessage, inputLabel, endpoint }: ForumFormProps) => {
    const [author, setAuthor] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState('')
    const { state } = useAppState()

    const addMessage = () => {
        setMessageError('')
        if (message.length < 1) {
            setMessageError('Zadejte text zprávy, kterou chcete odeslat.')
            return
        }
       
        saveForumMessage({
            forum: forum,
            message: {
                Author: author,
                Message: message
            },
            endpoint: endpoint,
            token: state?.token
        }).then(res => {
            if (res.success) {
                setMessage('')
                reloadMessage()
            }
        })
    }

    return (
        <div className='forum_form'>
            <TextInput
                type={ETextInputType.INPUT}
                label={inputLabel ? inputLabel : 'Jméno'}
                value={author}
                errorMessage=''
                setValue={e => setAuthor(e.target.value) }
            />
            <TextInput
                type={ETextInputType.TEXTAREA}
                label='Zpráva'
                required
                value={message}
                errorMessage={messageError }
                setValue={e => setMessage(e.target.value)}
            />
            <Button text='Odeslat' onClick={addMessage }/>
        </div>
        )
}