import { MdOutlineCheckCircle } from "react-icons/md";
import { EMessageType } from "../../utils";
import { Message } from "./Message";

type SuccessMessageProps = {
    text: string
}

export const SuccessMessage = ({ text }: SuccessMessageProps) => {
    return (
        <Message text={text} icon={<MdOutlineCheckCircle size={25} />} type={EMessageType.SUCCESS} />
    )
}