import { TUser } from "../types"
import { get, post } from "./axiosClient"

type RegisterUserProps = {
    name: string
}

type RegisterUserResponse = {
    token?: string,
    errorMessage?: string
}

export const registerUser = async ({ name }: RegisterUserProps): Promise<RegisterUserResponse> => {
    return await post({
        data: {},
        params: {
            name: name
        },
        path: 'users'
    }).then((res) => {
        if (res.status === 200) {
            return { token: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}

type GetUserProps = {
    token: string
}

type GetUserResponse = {
    user?: TUser,
    errorMessage?: string
}

export const getUser = async ({ token }: GetUserProps): Promise<GetUserResponse> => {
    return await get({
        params: {},
        path: 'users',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { user: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}

type GetUserDetailProps = {
    token: string
}

export const getUserDetail = async ({ token }: GetUserDetailProps): Promise<GetUserResponse> => {
    return await get({
        params: {
            detail: true
        },
        path: 'users',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { user: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}

type SaveUserProps = {
    user: TUser,
    token: string
}

type SaveUserResponse = {
    success: boolean,
    errorMessage?: string
}

export const saveUser = async ({ user, token }: SaveUserProps): Promise<SaveUserResponse> => {
    return await post({
        data: JSON.stringify(user),
        params: {},
        path: 'users',
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else {
            return {
                errorMessage: res.data,
                success: false
            }
        }
    })
}