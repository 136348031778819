import { Headline, HistoryMenu, Paragraph } from "../../components";
import { HistoryPageProps } from "../../types";
import { EHistoryPage } from "../../utils";

export const About = ({ setPage, year}: HistoryPageProps) => {

    return (
        <div className='page'>
            <HistoryMenu selectedPage={EHistoryPage.ABOUT} setPage={setPage } />
            <Headline text='O hře' size={1} />
            <Paragraph>
                Hra pro 176 týmů začala u školy v Dolních Měcholupech klasickou startovní šifrovací aktivitou, na kterou navázala první šifra.
                Poté hráči pokračovali přes Horní Měcholupy na hradiště Hostivař, kde se ukázalo, zda po cestě luštili minišifřičky, které byly specialitou letošního ročníku.
                Po Multišifře pokračovali k jídelní šifře, kterou byly letos bonbony Tic-Tac, aby potom oběhali Skřítky pro radost v Milíčovském lese a skončili v Modré škole.
            </Paragraph>
            <Paragraph>
               Vítězem tohoto ročníku se stal tým Rychlonohý a celkem 32 týmů dosáhlo na plný počet bodů, do cíle dorazilo krásných 137 týmů, 120 z nich včas odevzdalo cílové heslo a ukončilo tak úspěšně hru. Všem gratulujeme a děkujeme za účast.
            </Paragraph>
            <Paragraph>
                <>
                    Jako každý rok, i letos jste od nás dostali <a href="https://www.sifrovacihra.cz/zkratka/2024/Pruvodce.pdf" target="_blank" className="href"> Turistický průvodce Zkratkou</a>, tak pokud jste jej nestihli přečíst na hře, můžete to napravit teď.
                </>
            </Paragraph>

            <Headline text='Reportáže' size={ 3}/>
            <Paragraph>
                <ul>
                    <li><a href="https://docs.google.com/document/d/1EFEWAAnfRPoddXPodsJHnOdMsBM_IOMHX8I_ZLXrO7I/edit?usp=sharing" target="_blank" className="href">Organizátorská očekávání vs realita</a></li>
                    <li><a href="https://docs.google.com/document/d/1kYyPU5yIsasBLTskWLUjwhnFnavurd_exKxEX-zhbaU/edit?usp=sharing" target="_blank" className="href">Reportáž od týmu Rychlonohý</a></li>
                    <li>A doufáme v ještě nějaké ;)</li>
                </ul>
            </Paragraph>

            <Headline text='Fotky' size={3} />
            <Paragraph>
                <ul>
                    <li><a href="https://www.rajce.idnes.cz/martinmach/album/zkratka-2024" target="_blank" className="href">Fotky od Ondry</a></li>
                    <li><a href="https://www.rajce.idnes.cz/martinmach/album/zkratka-2024-od-ph" target="_blank" className="href">Fotky od Petra</a></li>
                    <li><a href="https://www.rajce.idnes.cz/martinmach/album/zkratka-2024-martin" target="_blank" className="href">Fotky od Martina</a></li>
                    <li><a href="https://www.rajce.idnes.cz/martinmach/album/zkratka-2024-od-jeffera" target="_blank" className="href">Fotky od Jeffera</a></li>
                </ul>
            </Paragraph>

            <Headline text='Poděkování' size={3} />
            <Paragraph>
                <>
                    Moc děkujeme všem, kteří nám se hrou pomáhali.
                    <ul>
                        <li>B-týmu ve složení Jeffer, Týna, Medvěd, Petr Hála.</li>
                        <li>Testerům, kterými letos byli tým Voy-a-ger; tým Hrochologové; Mirek; Dan Janek; orgové <a href="https://lamolamy.cz/" target="_blank" className="href">Lamolam</a>; Jeffer; Emil; Karel; Ondrovi kolegové se svými kamarády; Honza, Katka, Vojta, Magda a Marian.</li>
                        <li>Také bychom rádi poděkovali Modré škole za pronájem cílových prostor a vstřícné jednání.</li>
                        <li>Dále kopírování Havránek za super ochotu, cenu a rychlost při tisku šifer.</li>
                        <li>A v neposlední řadě cukrárně Lucie za krásný a výborný dort.</li>
                    </ul>
                    Snad jsme na nikoho nezapomněli, kdyžtak se prosím připomeňte.
                </>
            </Paragraph>
        </div>
    )
}