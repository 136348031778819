type SpacerProps = {
    size?: number;
}

export const Spacer = ({ size }: SpacerProps) => {
    const n = size ? size : 1

    return (
        <>
            {
                Array.from({ length: n }, (_, i) => <div className='spacer' key={i} />)
            }
        </>
    )
}