import './menuItem.scss'
import { FaPaw } from "react-icons/fa"
import { EPage } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { useAction } from '../../../hooks'

type MenuItemProps = {
    text: string,
    page: EPage,
    icon?: JSX.Element 
}

export const MenuItem = ({ page, text, icon}: MenuItemProps) => {
    const navigate = useNavigate()
    const { setAppSmallMenuAction } = useAction()

    const onMenuClick = () => {
        navigate(page)
        setAppSmallMenuAction(false)
    }

    return <div className='menu_item' onClick={onMenuClick}>
        <div className='menu_item__icon'>
            {icon ? icon : <FaPaw />}
        </div>
        <div className='menu_item__text'>
            {text}
        </div>
    </div>
}