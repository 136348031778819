import { useState } from "react"
import { EAdminPage } from "../../utils"
import { Contacts } from "./Contacts"
import { News } from "./News"
import { Passwords } from "./Passwords"
import { Payments } from "./Payments"


export const Admin = () => {

    const[page, setPage] = useState(EAdminPage.PAYMENTS)

    const getPage = () => {
        switch (page) {
            case EAdminPage.PAYMENTS: return <Payments setPage={setPage } />
            case EAdminPage.NEWS: return <News setPage={setPage} />
            case EAdminPage.CONTACTS: return <Contacts setPage={setPage} />
            case EAdminPage.PASSWORD: return <Passwords setPage={setPage} />
        }
    }

    return (
        <>
            
            {getPage() }
        </>
        )
}