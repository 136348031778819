import { TForumMessage } from "../types";
import { EForumEndpoint, EGame } from "../utils";
import { get, post } from "./axiosClient";

type SaveForumMessageProps = {
    message: TForumMessage,
    forum: string,
    endpoint: EForumEndpoint,
    token?: string
}

interface SaveForumMessageResponse {
    success: boolean,
    errorMessage?: string
}

export const saveForumMessage = async ({ message, forum, endpoint, token }: SaveForumMessageProps): Promise<SaveForumMessageResponse> => {
    return await post({
        data: JSON.stringify(message),
        params: { forum: forum },
        path: endpoint,
        token: token
    }).then((res) => {
        if (res.status === 200) {
            return { success: true }
        } else {
            return {
                errorMessage: res.data,
                success: false
            }
        }
    });
}

type GetForumMessageProps = {
    forum: string
}

interface GetForumMessageResponse {
    messages?: TForumMessage[],
    errorMessage?: string
}

export const getForumMessages = async ({ forum }: GetForumMessageProps): Promise<GetForumMessageResponse> => {
    return await get({
        params: { forum: forum },
        path: 'forum'
    }).then((res) => {
        if (res.status === 200) {
            return { messages: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}

export const getCypherMessages = async (): Promise<GetForumMessageResponse> => {
    return await get({
        params: { },
        path: 'forum'
    }).then((res) => {
        if (res.status === 200) {
            return { messages: res.data }
        } else {
            return { errorMessage: res.data }
        }
    });
}