import { EAdminPage } from "../../../utils"
import '../subMenu.scss'

type AdminMenuProps = {
    setPage: any,
    selectedPage: EAdminPage
}

export const AdminMenu = ({ setPage, selectedPage }: AdminMenuProps) => {
    return (
        <div className='sub_menu'>
            <div className={'sub_menu__item' + (selectedPage == EAdminPage.PAYMENTS ? ' selected' : '')} onClick={() => setPage(EAdminPage.PAYMENTS)}>
                Platby
            </div>
            <div className={'sub_menu__item' + (selectedPage == EAdminPage.NEWS ? ' selected' : '')} onClick={() => setPage(EAdminPage.NEWS)}>
                Novinky
            </div>
            <div className={'sub_menu__item' + (selectedPage == EAdminPage.CONTACTS ? ' selected' : '')} onClick={() => setPage(EAdminPage.CONTACTS)}>
                Kontakty
            </div>
            <div className={'sub_menu__item' + (selectedPage == EAdminPage.PASSWORD ? ' selected' : '')} onClick={() => setPage(EAdminPage.PASSWORD)}>
                Hesla
            </div>
        </div>
        )
}