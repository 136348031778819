import { EMessageType } from "../../utils"
import './message.scss'

type MessageProps = {
    type: EMessageType,
    icon: JSX.Element,
    text: string
}
export const Message = ({ type, icon, text }: MessageProps) => {
    if (text.length < 1) {
        return <></>
    }
    return (
        <div className={'message ' + type}>
            <div className='message__icon'>{icon}</div>
            <div className='message__text'>{text }</div>
        </div>
    )
}