import { useEffect, useState } from "react";
import { Headline, HistoryMenu, Paragraph } from "../../components";
import { HistoryPageProps } from "../../types";
import { EHistoryPage } from "../../utils";
import { stats2024 } from '../../resources';

export const Stats = ({ setPage, year }: HistoryPageProps) => {


    return (
        <div className='page widther'>
            <HistoryMenu selectedPage={EHistoryPage.STATS} setPage={setPage} />
            <Headline text='Statistiky' size={1} />

            <Paragraph centered>
                <>
                    Statistiky jsou zpracovány i na <a href="https://statek.seslost.cz/zkratka-2024/" target="_blank" className="href"> Statku</a>.
                </>
            </Paragraph>
            <div dangerouslySetInnerHTML={{ __html: stats2024 }}/>
        </div>
    )
}