import { useEffect, useState } from "react"
import { TCypher, TSelect } from "../../types";
import { Select } from "../page";
import { Cypher } from "./Cypher";
import './cyphers.scss'

type CyphersProps = {
    cyphers: Array<TCypher>
}

export const Cyphers: React.FC<CyphersProps> = ({ cyphers }: CyphersProps) => {
    const [selectedCypher, setSelectedCypher] = useState<TSelect<number> | undefined>()
    const [cyphersSelect, setCyphersSelect] = useState<TSelect<number>[]>([])
    const [cypher, setCypher] = useState<TCypher>()

    useEffect(() => {
        setCyphersSelect(
            cyphers.map(
                c => ({ key: c.Id, label: `${c.Id}-${c.name}` })
            )
        )
        setCypher(cyphers.find(c => c.Id == 0))
    }, [cyphers])

    useEffect(() => {
        if (selectedCypher) {
            setCypher(cyphers.find(c => c.Id == selectedCypher?.key!!))
        }
    }, [selectedCypher])

    return (
        <>
            <Select label='Šifra:' selectedValue={selectedCypher} setSelectedValue={setSelectedCypher} values={cyphersSelect} />

            {
                cypher
                ? <Cypher cypher={cypher} />
                : <></>
            }

            <div className="clearfix"/>
                        
        </>
    )
}