import { useState } from "react";
import { FaPersonCirclePlus } from "react-icons/fa6";
import { reUnregisterTeam } from "../../../api";
import { useAppState } from "../../../hooks";
import { TUser } from "../../../types"
import { ConfirmModal } from "../../modal";
import './AdminTeam.scss'

type AdminTeamCancelledProps = {
    team: TUser,
    loadTeams: any,
    setError: any,
    setSuccess: any
}

export const AdminTeamCancelled = ({ team, loadTeams, setError, setSuccess }: AdminTeamCancelledProps) => {
    const [registerTeamConfirmModalVisible, setRegisterTeamConfirmModalVisible] = useState(false)
    const { state } = useAppState()

    const registerTeamAction = () => {
        setError('')
        setSuccess('')
        setRegisterTeamConfirmModalVisible(false)
        reUnregisterTeam({ idTeam: team.Id, token: state?.token! }).then(res => {
            if (res.success) {
                setSuccess('Týmu byla obnovena účast!')
            } else if (res.errorMessage) {
                setError(res.errorMessage)
            } else {
                setError('Došlo k nečekané chybě')
            }
            loadTeams()
        })
    }

    return (
        <>
            <div className='admin_team'>
                <div className='admin_team__register' title='Odregistrovat tým' onClick={() => setRegisterTeamConfirmModalVisible(true)}>
                    <FaPersonCirclePlus size={25} />
                </div>
                <div className='admin_team__name'>
                    {team.Name}
                </div>
            </div>
            <ConfirmModal
                headline='Obnovit registraci'
                text={`Opravdu chcete obnovit registraci týmu ${team.Name}?`}
                onCloseClick={() => setRegisterTeamConfirmModalVisible(false)}
                visible={registerTeamConfirmModalVisible}
                onConfirm={() => registerTeamAction()}
            />
        </>
    )
}